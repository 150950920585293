<template>
  <div class="title">
    <ion-label class="ion-text-start">{{ $t('product_detail.similar_product') }}</ion-label>
  </div>
  <div v-for="(item, index) in productItems" :key="index" @click="seeSimiliarProduct(item)">
    <NewProductItem :key="item.id" :item="item" :user="user" :selectedCompany="selectedCompany" />
  </div>
  <div class="title pt-2 mb-4">
    <ion-button
      class="button"
      fill="outline"
      expand="block"
      size="small"
      @click="$emit('explore-other-product')"
      >{{ $t('product_detail.explore_other_product') }}</ion-button
    >
  </div>
</template>
<script>
import NewProductItem from '@/components/product-item/NewCardProductItem.vue';
import { discountFn, priceDiscountFn, priceFn, weightFn } from '@/modules/b2b/services/libs/home';
import { defineComponent, inject, onMounted, ref } from 'vue';

export default defineComponent({
  emits: ['select-similar-product', 'explore-other-product'],
  components: {
    NewProductItem
  },
  props: {
    productItems: {
      type: [Object],
      default: new Array([])
    },
    user: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, { emit }) {
    const currencySymbol = ref(null);
    const selectedCompany = ref(null);
    const listImageSrc = ref({});
    const storage = inject('$storage');

    const seeSimiliarProduct = (itemData) => {
      emit('select-similar-product', itemData);
    };
    const onImageSetted = (opts) => {
      listImageSrc.value[opts.image] = opts.imageSrc;
    };

    onMounted(async () => {
      selectedCompany.value = await storage.getSelectedCompany();
      currencySymbol.value = props.user && props.user.currency_symbol;
    });
    return {
      currencySymbol,
      selectedCompany,
      listImageSrc,
      seeSimiliarProduct,
      onImageSetted,
      priceFn,
      weightFn,
      priceDiscountFn,
      discountFn
    };
  }
});
</script>
<style lang="scss" scoped>
.title {
  background: whitesmoke;
  font-weight: bold;
  font-size: 16px;
  padding: 16px;
}
</style>
