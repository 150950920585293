<template>
  <product-detail-skeleton v-if="!isShow && !opacity" />
  <ion-page class="detail-product" v-else>
    <ion-header
      class="ion-no-border"
      mode="ios"
      :class="['header-product', isShowHeader || !isOnline ? 'animation' : '']"
    >
      <ion-toolbar class="toolbar-header pl-1 pr-2">
        <ion-icon @click="backToPreviousPage" size="large" slot="start" :icon="chevronBackOutline"></ion-icon>
        <ion-icon slot="end" size="large" :icon="shareSocialOutline" @click="openSocialSharing"></ion-icon>
        <ion-label class="label-header-product">{{ item.name }}</ion-label>
      </ion-toolbar>
    </ion-header>
    <!-- content -->
    <ion-content
      ref="productDetailContent"
      class="modal-content"
      :scroll-events="true"
      @ionScroll="handleShowHeader($event)"
      :id="`product-details-${item?.id}`"
    >
      <ion-refresher slot="fixed" @ionRefresh="handleRefreshAndRefetchData($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <div v-if="!isOnline && isIosPlatform" class="mt-5 pt-5"></div>
      <div v-if="!isOnline" class="mt-5 pt-5">
        <pull-refresh></pull-refresh>
      </div>
      <div v-if="isOnline">
        <div
          style="margin-top: 3.1rem"
          v-show="!isShowHeader"
          :class="['back-btn', !isShowHeader && 'hidden-back']"
        >
          <ion-icon :icon="chevronBackOutline" @click="backToPreviousPage"></ion-icon>
          <ion-icon :icon="shareSocialOutline" @click="openSocialSharing"></ion-icon>
        </div>
        <div class="slide-img-videos mb-2">
          <SlidingImagesVideos
            :isProductOos="isProductOutOfStockRef"
            :image="item?.image"
            :itemsData="item"
            :isSingleVariant="item?.is_single_variant"
            :imagePath="IMAGE_PATH.PRODUCT"
            :imageDefault="DEFAULT_PRODUCT_IMAGE"
            :selectedVariant="specsSelected"
            :productMedia="item.attributes?.mediaAttributes"
            :skuMedia="skusSelected?.attributes?.mediaAttributes"
          />
        </div>
        <ion-grid class="px-4 pb-2">
          <ion-row>
            <ion-col size="12" class="ion-no-padding">
              <ion-label class="label-tenant">{{ item.tenant.tenant.name }} </ion-label>
            </ion-col>
            <ion-col size="12" class="ion-no-padding my-1">
              <ion-label class="label-product-name" ref="refNameProduct">{{ item.name }} </ion-label>
            </ion-col>
            <ion-col size="12" class="ion-no-padding">
              <ion-label v-if="item?.halal === true" class="label-halal">{{
                $t('sync_state.halal')
              }}</ion-label>
              <ion-label v-if="item?.halal !== null && item?.halal === false" class="label-non-halal">{{
                $t('sync_state.non_halal')
              }}</ion-label>
            </ion-col>
            <ion-col v-if="allowAttach && !showBookingId" size="12" class="ion-no-padding">
              <ion-label
                v-if="!isAllSkusOrderByWeight && !skusSelected?.is_order_by_weight"
                class="label-price"
                color="primary"
                >{{ price === `${currencySymbol} NaN` ? '' : price }}</ion-label
              >

              <ion-label
                class="label-kg"
                :class="isAllSkusOrderByWeight || skusSelected?.is_order_by_weight ? 'mt-2' : 'mb-2'"
              >
                {{ price !== `${currencySymbol} NaN` && price ? priceKgBelowTotalPrice : '' }}
              </ion-label>
              <ion-text
                v-if="isAllSkusOrderByWeight || skusSelected?.is_order_by_weight"
                class="order-by-weight"
                >{{ $t('order_by_weight') }}</ion-text
              >
            </ion-col>
            <ion-col v-else size="12" class="ion-no-padding">
              <ion-label v-if="!isAllSkusOrderByWeight" class="label-price" color="primary">{{
                price === `${currencySymbol} NaN` ? '' : priceDiscountUomFn(item, currencySymbol)
              }}</ion-label>

              <ion-label
                class="label-kg"
                :class="isAllSkusOrderByWeight || skusSelected?.is_order_by_weight ? 'mt-2' : 'mb-2'"
              >
                {{ setTotalPricePerUomFn(item, currencySymbol, isCalculateByWeight, fromText) }}
              </ion-label>
              <ion-text
                v-if="isAllSkusOrderByWeight || skusSelected?.is_order_by_weight"
                class="order-by-weight"
                >{{ $t('order_by_weight') }}</ion-text
              >
            </ion-col>
          </ion-row>
        </ion-grid>
        <div class="bg-gray"></div>
        <ion-grid class="px-4">
          <ion-row class="d-flex">
            <ion-col size="4" class="ion-no-padding">
              <ion-label class="label-pack" color="grey" v-if="!item.is_single_variant">{{
                $t('product_detail.select_variation')
              }}</ion-label>
              <div>
                <ion-badge
                  slot="start"
                  class="oos-badge is-single-variant"
                  color="primary"
                  fill="outline"
                  v-if="
                    item.is_single_variant &&
                    item.skusData[0]?.is_purchased &&
                    !item.skusData[0]?.is_out_of_stock
                  "
                  >{{ $t('product_detail.buy_again') }}</ion-badge
                >
              </div>
            </ion-col>
            <ion-col size="8" class="ion-no-padding d-flex flex-column align-end">
              <ion-row
                class="ion-align-items-end"
                v-if="item.is_single_variant && item.skusData[0]?.is_out_of_stock"
              >
                <ion-text class="quantity">{{ $t('product_detail.stock') }}:</ion-text>
                <ion-text class="quantity ml-1" color="danger">{{
                  $t('product_detail.out_of_stock')
                }}</ion-text>
              </ion-row>
              <ion-row class="ion-align-items-end" v-else>
                <ion-text class="quantity">{{ $t('quantity') }}:</ion-text>
                <ion-text
                  v-if="!skusSelected?.is_order_by_weight"
                  class="quantity ml-1"
                  :color="isQuatityAndStock ? 'primary' : 'grey'"
                >
                  {{
                    isQuatityAndStock === null
                      ? '-'
                      : !isQuatityAndStock
                      ? '0'
                      : `${+quantity > 0 ? quantity : 0}` +
                        ' ' +
                        $t('in') +
                        ' ' +
                        stock +
                        ' ' +
                        $t('stock_locations')
                  }}
                </ion-text>
                <ion-text v-else class="quantity ml-1" :color="isQuatityAndStock ? 'primary' : 'grey'">
                  {{
                    isQuatityAndStock === null
                      ? '-'
                      : !isQuatityAndStock
                      ? '0'
                      : `${+quantity > 0 ? quantity : 0}` +
                        ' KG ' +
                        $t('in') +
                        ' ' +
                        stock +
                        ' ' +
                        $t('stock_locations')
                  }}
                </ion-text>
              </ion-row>
              <ion-row class="ion-align-items-end backorder">
                <ion-text class="quantity-black">{{ $t('back_order') }}:</ion-text>
                <ion-text
                  class="quantity mt-0 ml-1"
                  :color="isBackOrder === null ? 'grey' : isBackOrder ? 'primary' : 'danger'"
                >
                  {{
                    isBackOrder === null ? '-' : isBackOrder ? $t('Available') : $t('unavailable')
                  }}</ion-text
                >
              </ion-row>
            </ion-col>
          </ion-row>
          <ion-row class="mt-2" v-if="!item.is_single_variant">
            <ion-col
              class="ion-no-padding mt-1"
              size="12"
              v-for="(specs, keyName) in getSpecsByKey"
              :key="keyName"
            >
              <ion-row>
                <ion-label class="label-keyname">{{ keyName }}</ion-label>
              </ion-row>
              <ion-label class="label-select-another">{{
                allowAttach &&
                JSON.stringify(skusSelected?.specs).includes(`${keyName}`) &&
                specs.length > 1 &&
                isOutofStockRef
                  ? ' ' + $t('select_another_option')
                  : ''
              }}</ion-label>
              <div class="d-flex flex-wrap mt-1">
                <div v-for="spec in specs" :key="spec.value" class="form-btn mt-2">
                  <ion-badge
                    slot="start"
                    class="oos-badge is-oos"
                    color="medium"
                    v-if="showOosAndBuyAgainStatus(spec.value).showOos"
                    >{{ $t('product_detail.sold_out') }}</ion-badge
                  >
                  <ion-badge
                    slot="start"
                    class="oos-badge buy-again"
                    color="primary"
                    fill="outline"
                    v-if="showOosAndBuyAgainStatus(spec.value).showBuyAgain"
                    >{{ $t('product_detail.buy_again') }}</ion-badge
                  >
                  <ion-button
                    mode="ios"
                    class="variant-chip btn-off-clickable"
                    :class="specsSelected.includes(spec.value) ? 'selected' : ''"
                    v-if="showOosAndBuyAgainStatus(spec.value).showOos"
                    fill="outline"
                    @click="checkSpecItem(spec.value, keyName)"
                    :disabled="specsSelected.length > 0 && !isAnySelectedIdIncluded(spec.ids)"
                  >
                    {{ spec.value }}
                  </ion-button>
                  <ion-button
                    mode="ios"
                    v-else
                    class="variant-chip"
                    :class="specsSelected.includes(spec.value) ? 'btn-primary' : 'btn-grey'"
                    fill="outline"
                    @click="checkSpecItem(spec.value, keyName)"
                    :disabled="specsSelected.length > 0 && !isAnySelectedIdIncluded(spec.ids)"
                  >
                    {{ spec.value }}
                  </ion-button>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
        <div class="bg-gray"></div>
        <ion-grid class="px-4">
          <ion-row>
            <ion-col size="6" class="ion-no-padding">
              <ion-label class="label-pack mt-3">{{ $t('packaging_size') }}</ion-label>
              <ion-label class="label-weight mt-1">{{ packagingSize ? packagingSize : '-' }}</ion-label>
            </ion-col>
            <ion-col size="6" class="ion-no-padding">
              <ion-label class="label-pack mt-3">{{ $t('weight') }}</ion-label>
              <ion-text
                v-if="isAllSkusOrderByWeight || skusSelected?.is_order_by_weight"
                class="order-by-weight mt-1"
                >{{ $t('order_by_weight') }}</ion-text
              >
              <ion-label v-else class="label-weight mt-1">{{ weight ? weight + ' ' + 'kg' : '-' }}</ion-label>
              <tooltip v-if="skusSelected?.is_catch_weight" />
            </ion-col>
            <ion-col size="12" class="mt-2 ion-no-padding">
              <ion-label class="label-pack mt-3">{{ $t('description') }}</ion-label>
              <ion-label ref="refDescription" :class="['label-weight', isShowMore ? 'overflow' : '']"
                >{{ item.description ? item.description : '' }}
              </ion-label>
              <ion-label v-if="isDisplayShowMore" @click="handleShowMore" class="label-showmore">{{
                isShowMore ? $t('show_more') : $t('show_less')
              }}</ion-label>
            </ion-col>
            <ion-col size="6" class="ion-no-padding mt-2" v-for="key in mappedTaggingSkusData" :key="key">
              <div class="d-flex flex-row align-end align-content-end">
                <ion-label class="label-pack mr-1">{{ key.key }}</ion-label>
                <ion-icon
                  v-if="key.key === 'Glazing'"
                  @click="setOpenAttributesInfo(true)"
                  :icon="informationCircleOutline"
                  class="info-icon"
                ></ion-icon>
                <ion-toast
                  :is-open="isOpenAttributesInfo"
                  :duration="3000"
                  :icon="informationCircleOutline"
                  :message="$t('glazing_desc')"
                  mode="ios"
                  color="dark"
                  position="middle"
                  :buttons="okButtons"
                  @didDismiss="setOpenAttributesInfo(false)"
                ></ion-toast>
              </div>
              <ion-label class="label-weight"> {{ key.value ? key.value : '-' }}</ion-label>
            </ion-col>

            <ion-col size="12" class="mt-2 ion-no-padding">
              <ion-label class="label-pack mt-3">{{ $t('category_sub') }}</ion-label>
              <div class="form-category my-1 mb-2">
                <ion-label class="label-category-main">{{ item.categories?.main_categories.name }}</ion-label>
                <ion-label class="label-category-sub ml-1">{{
                  item.categories?.sub_categories.name
                }}</ion-label>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
        <!-- product visibility section -->
        <div class="bg-gray"></div>
        <ion-grid class="px-4">
          <ion-row class="product-visibility">
            <ion-col size="12" class="ion-no-padding">
              <ion-text class="sub-title">{{
                $t('product_detail.merchant_product_discoverability')
              }}</ion-text>
              <div v-if="item.discoverability === 'view_and_order'">
                <ion-text class="d-flex mt-2 status view-and-order">{{
                  $t('product_detail.view_and_order')
                }}</ion-text>
                <ion-text class="d-flex status-hint">{{ $t('product_detail.view_and_order_hint') }}</ion-text>
              </div>
              <div v-else-if="item.discoverability === 'view_only'">
                <ion-text class="d-flex mt-2 status view-only">{{ $t('product_detail.view_only') }}</ion-text>
                <ion-text class="d-flex status-hint">{{ $t('product_detail.view_only_hint') }}</ion-text>
              </div>
              <div v-else>
                <ion-text class="d-flex mt-2 status can-not-view">{{
                  $t('product_detail.can_not_view')
                }}</ion-text>
                <ion-text class="d-flex status-hint">{{ $t('product_detail.can_not_view_hint') }}</ion-text>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
        <!-- competitor price section -->
        <div class="bg-gray"></div>
        <ion-grid class="px-4">
          <ion-row>
            <ion-col size="12" class="ion-no-padding feed-back">
              <div
                class="d-flex justify-space-between align-items py-4"
                @click="isDisableFeedBack ? '' : setOpenModalFeedBack(true)"
              >
                <ion-text>{{ $t('competitor_price_feedback') }}</ion-text>
                <div class="d-flex align-items">
                  <ion-text :color="isDisableFeedBack ? 'medium' : 'primary'">{{ $t('add') }}</ion-text>
                  <ion-icon
                    :color="isDisableFeedBack ? 'medium' : 'primary'"
                    :icon="chevronForwardOutline"
                  ></ion-icon>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
        <div class="bg-gray"></div>
        <!-- display similar product items -->
        <div ref="similiarProduct" class="similiar-product-bg">
          <product-recommend-list
            v-if="(showSimiliarProduct || isProductOutOfStockRef) && similiarProductItems?.length > 0"
            :productItems="getSimiliarProductsData"
            :user="user"
            @select-similar-product="onSelectSimilarProduct"
            @explore-other-product="onExploreOtherProducts(item?.categories?.main_categories?.id)"
          ></product-recommend-list>
        </div>
      </div>
    </ion-content>
    <!-- footer -->
    <ion-footer v-if="isOnline" class="ion-no-border footer">
      <ion-toolbar class="ion-no-padding">
        <div>
          <ion-grid class="ion-no-padding">
            <ion-row class="footer-input" :class="{ 'mb-3': !checkDisableAddToCart }">
              <ion-col size="6" class="pl-4 mt-2 mb-1">
                <div class="d-flex align-center" v-if="!showBookingId">
                  <ion-label class="label-total-price">{{ $t('total_price') }}</ion-label>
                  <ion-label class="label-special-price" v-if="isSpecialPrice">
                    {{ $t('special_price') }}</ion-label
                  >
                  <ion-label class="label-special-price" v-if="isDirectPrice">
                    {{ $t('direct_price') }}</ion-label
                  >
                </div>
                <div v-if="showBookingId" class="d-flex flex-column">
                  <ion-label> {{ $t('booking_date') }}: </ion-label>
                  <ion-label class="booking-date mt-2">
                    {{ bookingDate }}
                  </ion-label>
                  <ion-label class="booking-id mt-2">
                    {{ $t('booking') }}: {{ item?.booking_order_id }}
                  </ion-label>
                </div>
                <div v-if="!showBookingId">
                  <ion-label v-if="allowAttach && skusSelected" class="label-sum-price">
                    <span v-if="skusSelected?.is_order_by_weight">
                      {{ showPriceWeight }}
                    </span>
                    <span v-else>
                      {{ showPriceQuantity }}
                    </span>
                  </ion-label>
                  <ion-label v-else class="label-sum-price">-</ion-label>
                </div>
                <div v-if="!showBookingId">
                  <ion-label v-if="allowAttach && skusSelected" class="label-price-kg">{{
                    priceKgBelowTotalPrice
                  }}</ion-label>
                  <ion-label v-else class="label-price-kg">-</ion-label>
                </div>
              </ion-col>
              <ion-col size="6" class="d-flex align-items-center pr-4 mt-2 mb-1">
                <div v-if="skusSelected?.is_order_by_weight" class="d-flex align-self-center">
                  <ion-input
                    type="number"
                    :readonly="(isOutofStockRef && allowAttach) || !allowAttach"
                    class="input-weight"
                    :value="formattedWeight"
                    @ionInput="onInputWeight"
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || (event.target.value.indexOf('.') === -1 && event.charCode === 46)"
                  />
                  <span class="ml-1 mt-2">{{ $t('kg') }}</span>
                </div>
                <div class="d-flex cnt-weight h-100 form-input" v-else>
                  <div class="align-self-center text-center">
                    <div
                      v-if="!isOutofStockRef && allowAttach"
                      class="cnt-icon-q mr-2"
                      @click="minusQuantity"
                    >
                      <ion-icon class="icon-q" :icon="remove"></ion-icon>
                    </div>
                    <div v-else class="cnt-icon-q-outline mr-2" @click="minusQuantity">
                      <ion-icon class="icon-q" :icon="removeCircleOutline"></ion-icon>
                    </div>
                  </div>
                  <div v-if="!isOutofStockRef && allowAttach" size="5" class="align-self-center text-center">
                    <input
                      v-model.number="orderQuantity"
                      type="number"
                      maxlength="4"
                      class="input-weight"
                      @input="orderQuantityChange"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                    />
                  </div>
                  <div v-else size="5" class="align-self-center text-center">
                    <ion-input readonly v-model.number="outOfStockQty" type="text" class="input-weight" />
                  </div>
                  <div class="align-self-center text-center">
                    <div
                      v-if="!isOutofStockRef && allowAttach"
                      class="ml-2"
                      :class="{
                        'cnt-icon-q-disabled': disabledAddQuantity,
                        'cnt-icon-q': !disabledAddQuantity
                      }"
                      @click="addQuantity"
                    >
                      <ion-icon class="icon-q" :icon="add"></ion-icon>
                    </div>
                    <div v-else class="cnt-icon-q-outline ml-2" @click="addQuantity">
                      <ion-icon :icon="addCircleOutline"></ion-icon>
                    </div>
                  </div>
                </div>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="7" />
              <ion-col v-if="checkDisableAddToCart && allowAttach" size="5" class="ion-no-padding">
                <ion-text color="danger">{{
                  $t('soldInUnits', { number: skusSelected?.increment_qty })
                }}</ion-text>
              </ion-col>
            </ion-row>
          </ion-grid>
          <div class="d-flex align-center form-submit mb-2" :class="!allowAttach ? 'pt-2' : ''">
            <!-- quoted price button -->
            <ion-button
              v-if="!showBookingId"
              fill="outline"
              tabindex="-1"
              class="btn-add-to-cart mr-0 ml-3"
              :disabled="!allowAttach"
              :color="!colorGrayQuoteBtn ? 'primary' : 'medium'"
              expand="block"
              @click="setOpen(true)"
            >
              {{ $t('quote_price') }}
            </ion-button>

            <!-- add to cart -->
            <div class="flex-grow-1 btn-add-to-card">
              <ion-button
                v-if="!isOutofStockRef"
                tabindex="-1"
                class="btn-add-to-cart"
                :class="allowAttach && 'mr-0'"
                :disabled="
                  checkDisableAddToCart ||
                  isDisabledButtonByWeight ||
                  isOutofStockRef ||
                  !allowAttach ||
                  disableOrderByWeight
                "
                :color="!colorGrayBtn ? 'primary' : 'medium'"
                expand="block"
                @click="handleCheckLatetestPrice"
              >
                <span class="ml-1" v-if="!isAddToCartLoading">{{
                  allowAttach ? $t('add_to_cart') : $t('select_variation')
                }}</span>
                <ion-spinner name="crescent" v-else></ion-spinner>
              </ion-button>

              <!-- oos stock button -->
              <ion-button
                v-if="isOutofStockRef"
                tabindex="-1"
                class="btn-add-to-cart mr-0"
                fill="solid"
                :disabled="true"
                color="medium"
                expand="block"
              >
                <span v-if="!checkOtherVariation" class="ml-1"> {{ $t('out_of_stock') }}</span>
                <ion-spinner v-else name="dots"></ion-spinner>
              </ion-button>
            </div>

            <!-- favorite -->
            <div>
              <btn-favorite
                :isFavorite="skusSelected?.is_favorite ?? false"
                :selectedCompany="selectedCompany"
                :skusSelected="skusSelected"
                :allowAttach="allowAttach"
                @update-local-favorite="updateLocalFavorite"
              />
            </div>
          </div>
        </div>
      </ion-toolbar>
    </ion-footer>

    <ion-modal
      ref="modal"
      :initial-breakpoint="1"
      :breakpoints="[0, 0.25, 0.5, 0.75, 1]"
      mode="ios"
      css-class="default-bottom-sheet-modal"
      :is-open="isOpenRef"
      @didDismiss="setOpen(false)"
    >
      <QuotePrice
        :currencySymbol="currencySymbol"
        :selectedCompany="selectedCompany"
        :skusSelected="skusSelected"
        :weight="weight"
        :isShowDirectPriceQuoted="isShowDirectPriceQuoted"
        :item="item"
        :productId="productId"
        :tenantId="tenantId"
        :price="price"
        @closeModal="setOpen(false)"
        @onUpdateProductDetails="getSaleProductDetail"
      />
    </ion-modal>
    <ion-modal
      ref="modal"
      :initial-breakpoint="1"
      :breakpoints="[0, 0.25, 0.5, 0.75, 1]"
      mode="ios"
      css-class="default-bottom-sheet-modal"
      :is-open="isOpenModalFeedBack"
      @didDismiss="setOpenModalFeedBack(false)"
    >
      <ModalFeedBack
        @closeModal="setOpenModalFeedBack(false)"
        :skusSelected="skusSelected"
        :currencySymbol="currencySymbol"
        :selectedCompany="selectedCompany"
      />
    </ion-modal>
    <ion-modal
      ref="modal-other-variant"
      :initial-breakpoint="1"
      :breakpoints="[0, 0.25, 0.5, 0.75, 1]"
      mode="ios"
      css-class="modal-instruction"
      :is-open="isOpenSelectOtherVariant"
      @didDismiss="setOtherVariantModal(false)"
    >
      <SelectOtherVariant
        @closeSelectOtherVariantModal="setOtherVariantModal(false)"
        :getSpecsOosByKey="getSpecsOosByKey"
        :getSpecsOosById="getSpecsOosById"
        :selectedCompany="selectedCompany"
        :user="user"
        :oosList="oosList"
        :currencySymbol="currencySymbol"
        :productId="productId"
        :tenantId="tenantId"
        :item="item"
        @updateSkusfavorite="changeSkuFavorite"
        @refreshFavoriteList="$emit('refresh-favorite-list')"
      />
    </ion-modal>
    <ion-modal
      mode="ios"
      backdrop-dismiss="false"
      :is-open="isOpenPriceUpdated"
      css-class="modal-price-updated"
      @didDismiss="setOpenModalPriceUpdate(false)"
    >
      <price-update-alert @close-modal="setOpenModalPriceUpdate(false)"></price-update-alert>
    </ion-modal>
  </ion-page>
</template>

<script>
import Tooltip from '@/components/molecules/tooltip/Tooltip.vue';
import SlidingImagesVideos from '@/components/sliding-images-videos';
import { apolloClient } from '@/main';
import { DEFAULT_PRODUCT_IMAGE, IMAGE_PATH } from '@/modules/b2b/constants';
import {
  priceDiscountUomFn,
  priceFn,
  setTotalPricePerUomFn,
  setWeightFn
} from '@/modules/b2b/services/libs/home';
import { TYPE_SEARCH } from '@/modules/sale/constants';
import { saleGetCustomerDetail, saleGetLatestPrice } from '@/modules/sale/services/graphql';
import { displayPrice, getTomorrow } from '@/modules/sale/services/libs/helper';
import { ACTIONS as ACTIONS_CART } from '@/modules/sale/store/cart/actions';
import { MUTATIONS as MUTATIONS_CATEGORY } from '@/modules/sale/store/category/mutations';
import { ACTIONS as ACTIONS_SALE, ACTIONS as ACTION_OOS } from '@/modules/sale/store/product/actions';
import { MUTATIONS } from '@/modules/sale/store/product/mutations';
import ModalFeedBack from '@/modules/sale/views/product-detail/components/ModalFeedBack/index.vue';
import {
  DEFAULT_WEIGHT_UOM,
  INPUT_QUANTITY_BY_WEIGHT,
  MAX_INPUT,
  QUOTED_PRICED_BY
} from '@/modules/shared/constants/';
import { isLargerMaxInput, isQuantityAMultipleOfIncrement, useCheck } from '@/modules/shared/utils/';
import { setBadgeNumber } from '@/modules/shared/utils/badge';
import CleverTap from '@/services/shared/helper/clevertap';
import { useDateFormatter } from '@/usecases/global';
import { priceFormatter } from '@/utils/';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { Share } from '@capacitor/share';
import {
  alertController,
  isPlatform,
  onIonViewDidEnter,
  onIonViewWillLeave,
  toastController,
  useBackButton
} from '@ionic/vue';

import { useConfig, useGetStorageData } from '@/usecases/';
import {
  add,
  addCircleOutline,
  arrowDownOutline,
  checkmarkOutline,
  chevronBackOutline,
  chevronForwardOutline,
  heart,
  informationCircleOutline,
  paperPlaneOutline,
  remove,
  removeCircleOutline,
  shareSocialOutline,
  storefrontOutline
} from 'ionicons/icons';
import debounce from 'lodash.debounce';
import { computed, defineAsyncComponent, defineComponent, ref, watch } from 'vue';
import PriceUpdateAlert from '../cart/components/PriceUpdateAlert.vue';
import BtnFavorite from './components/BtnFavorite.vue';
import ProductRecommendList from './components/ProductRecommendList.vue';
import QuotePrice from './components/QuotePrice.vue';
import SelectOtherVariant from './components/SelectOtherVariant.vue';
import ProductDetailSkeleton from './skeleton.vue';
import functions from './utils';

export default defineComponent({
  name: 'ModalProductDetail',

  components: {
    Tooltip,
    // components
    BtnFavorite,
    ModalFeedBack,
    ProductRecommendList,
    ProductDetailSkeleton,
    SelectOtherVariant,
    PriceUpdateAlert,
    QuotePrice,
    SlidingImagesVideos,
    PullRefresh: defineAsyncComponent(() => import('@/modules/shared/views/refreshPage/PullRefresh.vue'))
  },
  setup() {
    const { router, store, t, route, storage } = useConfig();
    const { selectedUser, selectedCompany: selectedCompanyStorage } = useGetStorageData();
    const { formatDateMonthYear } = useDateFormatter();
    const { checkWeightItem, combineTwoUnit, isItemKGAndNoneUnit } = useCheck();
    const { getProductDetail, clearQueryProductDetail } = functions.useGetProductDetail();
    const STORE_PATH_PRODUCT = 'sale/product';
    const STORE_PATH_CART = 'sale/cart';
    const STORE_PATH_CATEGORY = 'sale/category';

    const currencySymbol = ref(null);
    const user = ref(null);
    const selectedTab = ref('');
    const tenantClassLevel = ref(null);
    const specsSelected = ref([]);
    const multiSelectedSpecs = ref([]);
    const selectedSkuIds = ref([]);
    const skusSelected = ref(null);
    const specsInvolved = ref([]);
    const skuIdsInvolved = ref([]);
    const selectedCompany = ref(null);
    const orderQuantity = ref(1);
    const outOfStockQty = ref(0);
    const orderWeight = ref(null);
    const isOpenRef = ref(false);
    const setOpen = (state) => {
      isOpenRef.value = state;
    };
    const isShowHeader = ref(false);
    const isShowMore = ref(true);
    const isDisplayShowMore = ref(false);
    const item = ref(null);
    const taggingSkuAttributes = ref(null);
    const isChangeSkuFavorite = ref(false);
    const isOpenSelectOtherVariant = ref(false);
    const isOutofStockRef = ref(false);
    const setOutOfStock = (state) => {
      isOutofStockRef.value = state;
    };
    const isProductOutOfStockRef = ref(false);
    const setProductOutOfStock = (state) => {
      isProductOutOfStockRef.value = state;
    };
    const refDescription = ref(null);
    const productDetailContent = ref(null);
    const similiarProduct = ref(null);
    const opacity = ref(0);
    const oosVariable = ref({
      mainCategoryId: '',
      isSingleVariant: false,
      productId: null,
      tenantId: null,
      buyerId: null,
      limit: 5,
      offset: 0
    });
    const isAddToCartLoading = ref(false);
    const isShow = ref(false);
    const checkOtherVariation = ref(false);
    const similiarProductItems = ref(null);
    const isOnline = ref(true);
    const reRenderProductImage = ref(1);
    const fromText = ref(t('orderB2b.from'));

    // Actions
    const saleUpdatePrice = async (params) =>
      await store.dispatch(`${STORE_PATH_PRODUCT}/${ACTIONS_SALE.SALE_UPDATE_PRICE}`, params);
    const updateSearch = async (params) =>
      await store.dispatch(`${STORE_PATH_PRODUCT}/${ACTIONS_SALE.UPDATE_SEARCH}`, params);
    const getSaleProductRecommend = async (params) =>
      await store.dispatch(`${STORE_PATH_PRODUCT}/${ACTION_OOS.GET_SALE_PRODUCTS_RECOMMEND}`, params);
    const resetProductRecommend = async () =>
      await store.dispatch(`${STORE_PATH_PRODUCT}/${ACTION_OOS.RESET_PRODUCT_RECOMMEND}`);
    const oosUpdateSearch = async (params) =>
      await store.dispatch(`${STORE_PATH_PRODUCT}/${ACTION_OOS.UPDATE_SEARCH}`, params);
    const getCartItems = async (params) =>
      await store.dispatch(`${STORE_PATH_CART}/${ACTIONS_CART.GET_CART_ITEMS}`, params);
    const addItemToCart = async (params) =>
      await store.dispatch(`${STORE_PATH_CART}/${ACTIONS_CART.ADD_ITEM_TO_CART}`, params);

    // Mutations
    const updatePriceWithoutReload = async () =>
      await store.commit(`${STORE_PATH_PRODUCT}/${MUTATIONS.UPDATE_PRICE_WITHOUT_RELOAD}`);
    const resetCategory = async () => await store.commit(`${STORE_PATH_PRODUCT}/${MUTATIONS.RESET_CATEGORY}`);
    const setProductIdAfterExplore = async (params) =>
      await store.commit(`${STORE_PATH_PRODUCT}/${MUTATIONS.SET_PRODUCT_ID_AFTER_EXPLORE}`, params);
    const exploreRelatedCategories = async (params) =>
      await store.commit(`${STORE_PATH_CATEGORY}/${MUTATIONS_CATEGORY.EXPLORE_RELATED_CATEGORIES}`, params);
    // functions
    const changeSkusSelected = (state = { sku_id: 0 }) => {
      skusSelected.value = state;
      if (state.weight) {
        orderWeight.value = state.weight.toFixed(2);
        if (!state.is_back_order && state.total_available_quantity > 0) {
          orderWeight.value =
            state.weight > state.total_available_quantity
              ? state.total_available_quantity.toFixed(2)
              : state.weight.toFixed(2);
        }
      } else {
        orderWeight.value = 0;
      }
    };
    const isOpenModalFeedBack = ref(false);
    const setOpenModalFeedBack = async (state) => (isOpenModalFeedBack.value = state);
    const openToast = async (message, color = 'primary', position = 'top') => {
      const toast = await toastController.create({
        message,
        position,
        color,
        duration: 700
      });
      return toast.present();
    };

    const setOrderQuantity = (value) => {
      orderQuantity.value = value;
    };

    const isOpenPriceUpdated = ref(false);
    const latesNewPrice = ref(null);
    const productId = ref(Number(route.params.id));
    const tenantId = ref(null);
    const isOpenAttributesInfo = ref(false);
    const setOpenAttributesInfo = (state) => {
      isOpenAttributesInfo.value = state;
    };
    const okButtons = [
      {
        text: 'OK',
        role: 'cancel'
      }
    ];

    const getSaleProductDetail = async () => {
      await resetProductRecommend();
      user.value = await selectedUser.value;
      const { tenant, currency_symbol } = await selectedUser.value;
      const { id: buyerId } = await selectedCompanyStorage.value;
      tenantId.value = tenant.id;
      currencySymbol.value = currency_symbol;
      const { data, error } = await getProductDetail({
        productId: +productId.value,
        tenantId: tenantId.value,
        buyerId
      });
      if (error.value) {
        await router.push({ path: '/sale/main/product-detail/error' });
        return;
      }
      item.value = data.value.getProductDetails;
      taggingSkuAttributes.value = data.value.getProductAttributesDescription?.attributes;
      await Promise.all([storage.getSelectedCompany()]).then((values) => {
        selectedCompany.value = values[0];
      });
      defaultSpecsSelected();

      // set oos status
      setProductOutOfStock(item.value.skusData.every((ele) => ele.is_out_of_stock));

      if (skusSelected.value) {
        orderWeight.value = skusSelected.value?.weight?.toFixed(2) ?? ``;
        if (!skusSelected.value.is_back_order && skusSelected.value.total_available_quantity > 0) {
          orderWeight.value =
            skusSelected.value.weight > skusSelected.value.total_available_quantity
              ? skusSelected.value.total_available_quantity.toFixed(2)
              : skusSelected.value.weight.toFixed(2);
        }
      } else {
        orderWeight.value = 0;
      }
      // recheck recommend product
      checkAndGetProductRecommend();
      isShow.value = true;
      await handleDisplayShowMore();
    };

    const checkAndGetProductRecommend = async () => {
      if (isProductOutOfStockRef.value) {
        checkOtherVariation.value = true;
        oosVariable.value.mainCategoryId = selectedTab.value;
        oosVariable.value.isSingleVariant = item.value.is_single_variant;
        oosVariable.value.productId = item.value.id;
        oosVariable.value.tenantId = item.value.tenant_id;
        oosVariable.value.buyerId = selectedCompany.value.id;
        await getSaleProductRecommend(oosVariable.value);
        setTimeout(() => {
          checkOtherVariation.value = false;
        }, 1000);
      }
    };

    const handleDisplayShowMore = debounce(() => {
      if (!refDescription.value || null) return;
      // Accessing the scrollHeight property using .value
      refDescription.value.$el.scrollHeight > 48
        ? (isDisplayShowMore.value = true)
        : (isDisplayShowMore.value = false);
    }, 500);

    // default if has only skusData
    const defaultSpecsSelected = () => {
      if (item.value.skusData.length === 1 && !item.value.skusData[0].specs) {
        changeSkusSelected({ ...item.value.skusData[0], image: item.value.image });
        const parseSpecs = { Single_Variant: true };
        specsSelected.value = Object.values(parseSpecs);
        specsInvolved.value = Object.values(parseSpecs);
      }
    };

    const handleShowHeader = debounce((event) => {
      event.detail.scrollTop > 270 ? (isShowHeader.value = true) : (isShowHeader.value = false);
    }, 100);

    const handleShowMore = () => {
      isShowMore.value = !isShowMore.value;
    };

    const onInputWeight = (event) => {
      const maxWeight = 999999.99;
      let value = event.target.value;
      const decimalIndex = value.indexOf('.');
      if (decimalIndex !== -1 && value.slice(decimalIndex + 1).length > 2) {
        // truncate to two decimal places
        value = Math.trunc(parseFloat(value) * 100) / 100;
        event.target.value = value;
      }
      orderWeight.value = value;
      if (!isBackOrder.value && orderWeight.value > remainingQuantity.value) {
        orderWeight.value = remainingQuantity.value.toFixed(2);
        event.target.value = orderWeight.value;
        openToast(t('out_of_max_weight', { weight: remainingQuantity.value.toFixed(2) }), 'danger');
      } else {
        if (formattedWeight.value > maxWeight) {
          openToast(t('out_of_max_weight', { weight: maxWeight }), 'danger');
          orderWeight.value = maxWeight.toFixed(2);
          event.target.value = orderWeight.value;
        }
      }
    };

    const minusQuantity = () => {
      if (isOutofStockRef.value || !allowAttach.value) {
        return;
      }
      const quantity = Number(orderQuantity.value) || 0;
      const increment = Number(skusSelected.value.increment_qty);

      if (quantity <= increment) {
        orderQuantity.value = increment;
      } else if (
        isQuantityAMultipleOfIncrement({
          quantity: quantity,
          increment: increment
        })
      ) {
        orderQuantity.value -= increment;
      } else {
        orderQuantity.value -= quantity % increment;
      }
    };

    const addQuantity = () => {
      if (isOutofStockRef.value || !allowAttach.value) {
        return;
      }
      const quantity = Number(orderQuantity.value) || 0;
      const increment = Number(skusSelected.value.increment_qty);
      if (isLargerMaxInput(quantity + increment)) {
        orderQuantity.value = MAX_INPUT;
      } else {
        if (!isBackOrder.value) {
          if (quantity < remainingQuantity.value) {
            orderQuantity.value = quantity + increment - (quantity % increment);
          } else {
            openToast(t('out_of_max_quantity', { quantity: remainingQuantity.value }), 'danger');
            orderQuantity.value = remainingQuantity.value - (remainingQuantity.value % increment);
          }
        } else {
          orderQuantity.value = quantity + increment - (quantity % increment);
        }
      }
    };

    const orderQuantityChange = () => {
      // process check input value
      if (!orderQuantity.value) return;

      orderQuantity.value = Number(`${orderQuantity.value}`.replace(/[^\d]/g, '')) || 0;
      if (isLargerMaxInput(orderQuantity.value)) {
        orderQuantity.value = MAX_INPUT;
      } else {
        if (!isBackOrder.value && orderQuantity.value > remainingQuantity.value) {
          const increment = Number(skusSelected.value.increment_qty);
          openToast(t('out_of_max_quantity', { quantity: remainingQuantity.value }), 'danger');
          orderQuantity.value = remainingQuantity.value - (remainingQuantity.value % increment);
        }
      }
      orderQuantity.value = `${orderQuantity.value}`;
    };
    const updateSkusSelectedById = (id) => {
      if (!id) {
        changeSkusSelected();
      } else {
        const index = item.value.skusData.findIndex((skus) => skus.sku_id == id);
        let data = {};
        if (index !== -1) {
          data = {
            ...item.value.skusData[index],
            image: item.value.image
          };
        }
        changeSkusSelected(data);
      }
    };

    const showAlert = async ({ header, message }) => {
      const alert = await alertController.create({
        mode: 'ios',
        header,
        message,
        buttons: [t('close')]
      });
      setTimeout(async () => await alert.present(), 200);
    };
    const handleRefreshAndRefetchData = async (event) => {
      await handleRefresh(event);
      if (isOnline.value) {
        await getSaleProductDetail();
      }
    };
    const handleRefresh = async (event) => {
      await detectConnection();
      event.target.complete();
    };

    const detectConnection = () => {
      const connection = navigator.onLine;
      if (connection) {
        isOnline.value = true;
      } else {
        isOnline.value = false;
      }
    };
    const showAlertErrPrice = async () => {
      const alert = await alertController.create({
        header: 'Oops!',
        mode: 'ios',
        message: t('something_went_wrong_please_try_again'),
        buttons: [
          {
            text: t('OK'),
            handler: async () => {
              await detectConnection();
              await handleGetCustomerDetail();
            }
          }
        ]
      });
      await alert.present();
    };

    const handleGetCustomerDetail = async () => {
      try {
        const { data } = await apolloClient.query({
          query: saleGetCustomerDetail,
          variables: {
            id: selectedCompany.value.id
          }
        });
        await storage.setSelectedCompany({
          ...selectedCompany.value,
          account_number: data.saleGetCustomerDetail.account_number
        });
        selectedCompany.value = await selectedCompanyStorage.value;
      } catch (error) {
        console.log(error);
      }
    };
    const setOtherVariantModal = async (value) => {
      isOpenSelectOtherVariant.value = value;
    };

    const handleCheckLatetestPrice = async () => {
      await getLatestPrice();
      await addToCart();
    };

    // add item to cart
    const addToCart = async () => {
      const historyState = window.history.state;
      const prevHistoryPath = historyState?.back;
      CleverTap.onUserAddToCart({ data: item.value, sku: skusSelected.value, orderQty: orderQuantity.value });

      if (skusSelected.value.price !== latesNewPrice.value[0].price) {
        setOpenModalPriceUpdate(true);
      } else {
        if (isAddToCartLoading.value) return false;
        isAddToCartLoading.value = true;
        const cartData =
          cart.value && cart.value.items.length !== 0
            ? cart.value
            : {
                items: [],
                delivery_date: getTomorrow(),
                delivery_time: '',
                // description: '',
                // po_number: '',
                standalone: 0
              };
        const item = {
          sku_id: skusSelected.value.sku_id,
          is_favorite: skusSelected.value.is_favorite,
          quotation_status: skusSelected.value.quotation_status,
          supplier_id: selectedCompany.value.id,
          order_qty: skusSelected.value.is_order_by_weight
            ? INPUT_QUANTITY_BY_WEIGHT
            : parseInt(orderQuantity.value),
          order_weight: orderWeight.value,
          selected: true
        };

        const params = {
          customer_id: selectedCompany.value.id,
          delivery_date: cartData.delivery_date,
          delivery_time: cartData.delivery_time,
          // description: cartData.description,
          // po_number: cartData.po_number,
          standalone: cartData.standalone === 1 ? true : false,
          item
        };

        await addItemToCart(params);
        await setBadgeNumber(store, selectedCompany.value.id);

        openToast(t('successfully_added_to_cart'));
        if (isOpenSelectOtherVariant.value === true) {
          isOpenSelectOtherVariant.value = false;
        }
        isAddToCartLoading.value = false;
        setProductIdAfterExplore(null);
        if (!prevHistoryPath) router.replace('/sale/main/home');
        else router.back();
      }
    };

    //Check latest items/product price
    const getLatestPrice = async () => {
      try {
        const { data } = await apolloClient.query({
          query: saleGetLatestPrice,
          variables: {
            skuIds: skusSelected.value.sku_id,
            accountNumber: selectedCompany.value.account_number
          }
        });
        latesNewPrice.value = data.saleGetLatestPrice;
      } catch (error) {
        showAlertErrPrice();
      }
    };

    //show price changes alert
    const setOpenModalPriceUpdate = async (params) => {
      isOpenPriceUpdated.value = params;
      if (!params) {
        await getSaleProductDetail();
        await updateSkusSelectedById(skusSelected.value.sku_id);
      }
    };

    // update local favorite - change skusSelected
    const updateLocalFavorite = () => {
      const newFavorite = !skusSelected.value.is_favorite;
      changeSkusSelected({
        ...skusSelected.value,
        is_favorite: newFavorite
      });

      item.value.skusData.forEach((skus) => {
        if (skus.sku_id == skusSelected.value.sku_id) {
          skus.is_favorite = newFavorite;
        }
      });
      //logic ticket mer-207
      isChangeSkuFavorite.value = true;
    };

    // click checked-spec and update list specs selected
    const unCheckSpecItem = (spec) => {
      const list = getSkusInfo.value;
      const index = specsSelected.value.indexOf(spec);
      if (index !== -1) {
        // remove 1 spec -> length - 1
        specsSelected.value.splice(index, 1);
        const { specsInvolved, skuIdsInvolved } = functions.getSpecsInvolvedBySpecs(
          specsSelected.value,
          list
        );
        specsInvolved.value = specsInvolved;
        skuIdsInvolved.value = skuIdsInvolved;

        // check if specsInvolved lenght = specsSelected length -> clear specsSelected
        if (specsSelected.value.length + 1 === specsInvolved.value.length) {
          specsSelected.value = [];
          const {
            specsInvolved: specsInvolvedTmp,
            skuIdsInvolved: skuIdsInvolvedTmp
          } = functions.getSpecsInvolvedBySpecs(specsSelected.value, list);
          specsInvolved.value = specsInvolvedTmp;
          skuIdsInvolved.value = skuIdsInvolvedTmp;
        }
      }
      updateSkusSelectedById(0);
    };

    const selectSpec = (specCategory, specValue) => {
      const existingSpecIndex = multiSelectedSpecs.value.findIndex((spec) => spec.category === specCategory);
      if (existingSpecIndex !== -1) {
        if (multiSelectedSpecs.value[existingSpecIndex].value !== specValue) {
          multiSelectedSpecs.value[existingSpecIndex] = { category: specCategory, value: specValue };
        } else if (multiSelectedSpecs.value[existingSpecIndex].value === specValue) {
          multiSelectedSpecs.value.splice(existingSpecIndex, 1);
          specsInvolved.value = specsInvolved.value.filter((item) => item !== specValue);
          skuIdsInvolved.value = [];
          skusSelected.value = null;
        }
      } else {
        multiSelectedSpecs.value.push({ category: specCategory, value: specValue });
      }
      return multiSelectedSpecs.value;
    };

    // click check-spec and update list specs selected
    const checkSpecItem = async (value, specCategory) => {
      const variantCount = Object.keys(getSpecsByKey.value).length;
      await selectSpec(specCategory, value);
      const specs = multiSelectedSpecs.value.map((spec) => spec.value);
      const list = getSkusInfo.value;
      const { skuIds, specsSelectedData } = functions.findSkuFromSpecs(specs, list);
      selectedSkuIds.value =
        variantCount > 1
          ? getAllowedSelectedSkusBySpecs(getSkusInfo.value, value)
          : getAllSkuIds(getSkusInfo.value);
      if (skuIds.length === 1) {
        updateSkusSelectedById(skuIds[0]);
        specsSelected.value = specsSelectedData;
        specsInvolved.value = specsSelectedData;
        skuIdsInvolved.value = skuIds;
      } else {
        specsSelected.value = specs;
        const {
          specsInvolved: specsInvolvedTmp,
          skuIdsInvolved: skuIdsInvolvedTmp
        } = functions.getSpecsInvolvedBySpecs(specs, list);
        specsInvolved.value = specsInvolvedTmp;
        skuIdsInvolved.value = skuIdsInvolvedTmp;
      }
    };

    const getAllSkuIds = (data) => {
      const skuIdsArray = [];
      for (let i = 0; i < data.length; i++) {
        skuIdsArray.push(data[i].skuId);
      }
      return skuIdsArray;
    };

    const checkSpecsListDataBySelectedSpecs = (data, value) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].specs.includes(value)) {
          return data[i].specs;
        }
      }
      return [];
    };

    const getAllowedSelectedSkusBySpecs = (data, selectedSpecs) => {
      const skuIdsData = [];
      const getAllSpecsListData = checkSpecsListDataBySelectedSpecs(data, selectedSpecs);

      for (let i = 0; i < data.length; i++) {
        const specs = data[i].specs;
        for (let j = 0; j < getAllSpecsListData.length; j++) {
          if (specs.includes(getAllSpecsListData[j])) {
            if (!skuIdsData.includes(data[i].skuId)) {
              skuIdsData.push(data[i].skuId);
            }
          }
        }
      }
      return skuIdsData;
    };

    const isAnySelectedIdIncluded = (selectedIds) => {
      return skuIdsInvolved.value.some((id) => selectedIds.includes(id));
    };

    const checkNotAvailableSpecs = (spec) => {
      const list = getSkusInfo.value;
      let isOosValue = false;

      for (let i = 0; i < list.length; i++) {
        if (list[i].specs.includes(spec)) {
          isOosValue = list[i].isOos;
          break;
        }
      }
      return isOosValue;
    };

    const checkIsBuyAgainSpecs = (spec) => {
      const list = getSkusInfo.value;
      let isBuyAgain = false;

      for (let i = 0; i < list.length; i++) {
        if (list[i].specs.includes(spec)) {
          isBuyAgain = list[i].isPurchased;
          break;
        }
      }
      return isBuyAgain;
    };

    const showOosAndBuyAgainStatus = (value) => {
      const list = getSkusInfo.value;
      const result = {
        showOos: true,
        showBuyAgain: false
      };
      if (!specsInvolved.value.length || specsInvolved.value.includes(value)) {
        list.forEach((item) => {
          if (
            (!skuIdsInvolved.value.length || skuIdsInvolved.value.includes(item.skuId)) &&
            item.specs.includes(value) &&
            !item.isOos
          ) {
            result.showOos = false;
          }
          if (
            !result.showOos &&
            item.isPurchased &&
            item.specs.includes(value) &&
            (!skuIdsInvolved.value.length || skuIdsInvolved.value.includes(item.skuId))
          )
            result.showBuyAgain = true;
        });
      } else {
        result.showOos = false;
      }

      return result;
    };

    const scrollToSimiliarProducts = () => {
      const contentElement = productDetailContent.value.$el;
      const similarProductElement = similiarProduct.value;

      if (contentElement && similarProductElement) {
        const targetOffsetTop = similarProductElement.offsetTop - 65;
        const targetOffsetLeft = similarProductElement.offsetLeft;

        contentElement.scrollToPoint(targetOffsetLeft, targetOffsetTop, 500);
      }
    };

    const setSearchParamsBackToDefault = async () => {
      await resetCategory();
      await updateSearch({
        search: {
          ...search.value,
          searchQueries: '',
          mainCategoryId: 'history',
          sortBy: 'purchased',
          sortType: 'desc',
          categories: [],
          offset: 0
        },
        type: TYPE_SEARCH.CATEGORY
      });
    };
    const onSelectSimilarProduct = async (itemParams) => {
      CleverTap.onUserViewRecommendProduct(itemParams);
      await setProductIdAfterExplore(item.value.id);
      await resetProductRecommend();

      router.replace({
        path: `/sale/main/product-detail/${itemParams.id}`
      });
    };
    const onExploreOtherProducts = async (tabCategory) => {
      // save the oos product category that need to explore
      exploreRelatedCategories(tabCategory.toString());
      specsSelected.value = [];
      specsInvolved.value = [];
      skusSelected.value = null;
      isOutofStockRef.value = false;
      router.push({ path: '/sale/main/home' });
    };
    const openSocialSharing = async () => {
      if (Capacitor.isNativePlatform()) {
        const deviceInfo = await Device.getInfo();
        CleverTap.onUserShareProduct(item.value);
        if (isPlatform('ios')) {
          await Share.share({
            title:
              t('product_detail.sharing_title', { product_name: item.value.name }) +
              `\n${process.env.VUE_APP_PRODUCT_SHARING_URL}/?url=main/product-detail/${item.value.id}`,
            text:
              t('product_detail.sharing_text', { product_name: item.value.name }) +
              `\n${process.env.VUE_APP_PRODUCT_SHARING_URL}/?url=main/product-detail/${item.value.id}`,
            url:
              t('product_detail.sharing_text', { product_name: item.value.name }) +
              `${process.env.VUE_APP_PRODUCT_SHARING_URL}/?url=main/product-detail/${item.value.id}`,
            dialogTitle: t('product_detail.sharing_dialog_title')
          });
        } else {
          await Share.share({
            title: t('product_detail.sharing_title', { product_name: item.value.name }),
            text: t('product_detail.sharing_text', { product_name: item.value.name }),
            url: `${process.env.VUE_APP_PRODUCT_SHARING_URL}/?url=main/product-detail/${item.value.id}`,
            dialogTitle: t('product_detail.sharing_dialog_title')
          });
        }
        CleverTap.onUserShareProductSuccess(deviceInfo);
      }
    };

    // computed
    const status = computed(() => store.getters[`${STORE_PATH_PRODUCT}/status`]);
    const itemComputed = computed(() => store.getters[`${STORE_PATH_PRODUCT}/item`]);
    const search = computed(() => store.getters[`${STORE_PATH_PRODUCT}/search`]);
    const list = computed(() => store.getters[`${STORE_PATH_PRODUCT}/list`]);
    const error = computed(() => store.getters[`${STORE_PATH_PRODUCT}/error`]);
    const listCache = computed(() => store.getters[`${STORE_PATH_PRODUCT}/listCache`]);
    const CachesaleUpdatePrice = computed(() => store.getters[`${STORE_PATH_PRODUCT}/CachesaleUpdatePrice`]);
    const productIdAfterExplore = computed(
      () => store.getters[`${STORE_PATH_PRODUCT}/productIdAfterExplore`]
    );
    const oosList = computed(() => store.getters[`${STORE_PATH_PRODUCT}/oosList`]);
    const cart = computed(() => store.getters[`${STORE_PATH_CART}/cart`]);

    const packagingSize = computed(() => {
      if (skusSelected.value) {
        const { unit_amount, uom, unit_per_item, unit_per_item_uom } = skusSelected.value;
        if (isItemKGAndNoneUnit(uom, unit_per_item)) {
          return '';
        }
        const itemsInProduct = checkWeightItem(unit_amount, uom);
        const unitsInItem = checkWeightItem(unit_per_item, unit_per_item_uom);
        return combineTwoUnit(itemsInProduct, unitsInItem);
      }
      return '';
    });
    const attributesData = computed(() => {
      let combineAttributes = null;
      if (
        skusSelected.value &&
        skusSelected.value?.attributes &&
        Object.keys(skusSelected.value?.attributes?.commonAttributes).length > 0
      ) {
        combineAttributes = {
          ...item.value?.attributes?.commonAttributes,
          ...skusSelected.value?.attributes.commonAttributes
        };
      } else {
        combineAttributes = {
          ...item.value?.attributes?.commonAttributes
        };
      }
      return combineAttributes;
    });
    const taggingSkusData = computed(() => {
      return Object.entries(attributesData.value).map(([key, value]) => ({ key, value }));
    });

    const mappedTaggingSkusData = computed(() => {
      return taggingSkusData.value.map(({ key, value }) => ({
        key: taggingSkuAttributes.value[key] || key,
        value
      }));
    });

    const allProductOos = computed(() => {
      return item.value?.skusData.every((sku) => sku.is_out_of_stock);
    });
    const showPriceQuantity = computed(() => {
      return skusSelected.value
        ? priceFormatter(currencySymbol.value, displayPrice(skusSelected.value.price * orderQuantity.value))
        : priceFormatter(currencySymbol.value, displayPrice(0));
    });

    const showPriceWeight = computed(() => {
      return skusSelected.value
        ? priceFormatter(
            currencySymbol.value,
            displayPrice((skusSelected.value.price / skusSelected.value.weight) * Number(orderWeight.value))
          )
        : priceFormatter(currencySymbol.value, displayPrice(0));
    });
    const weight = computed(() => {
      return skusSelected.value ? skusSelected.value.weight : 0;
    });

    const originPrice = computed(() => {
      return skusSelected.value && `${currencySymbol.value} ${displayPrice(skusSelected.value.origin_price)}`;
    });
    const price = computed(() => {
      return skusSelected.value?.sku_id ? skusSelected.value.display_price : '-';
    });
    const getSpecsByKey = computed(() => {
      return functions.sortListStrings(functions.getSpecsByKey(item.value));
    });
    const getSkusInfo = computed(() => {
      return functions.getSkusInfo(item.value);
    });
    const getSpecsOosByKey = computed(() => {
      return functions.sortListStrings(functions.getSpecsOosByKey(oosList.value?.oosList?.otherVariant));
    });
    const getSpecsOosById = computed(() => {
      return functions.getSpecsOosById(oosList.value?.oosList?.otherVariant);
    });
    const allowAttach = computed(() => {
      return skusSelected.value && skusSelected.value.sku_id !== 0;
    });
    const isDisabledButtonByWeight = computed(() => {
      const weight = Number(orderWeight.value);
      const quantity = Number(orderQuantity.value);
      if (skusSelected.value?.is_order_by_weight) {
        return weight <= 0;
      }
      return quantity <= 0;
    });
    const disableOrderByWeight = computed(() => {
      return skusSelected.value?.is_order_by_weight && skusSelected.value?.total_available_quantity
        ? orderWeight.value > skusSelected.value?.total_available_quantity
        : false;
    });
    const colorGrayBtn = computed(() => {
      return (
        isDisabledButtonByWeight.value ||
        checkDisableAddToCart.value ||
        isOutofStockRef.value ||
        !allowAttach.value ||
        disableOrderByWeight.value
      );
    });
    const packaging_size = computed(() => {
      return skusSelected.value &&
        skusSelected.value.uom !== DEFAULT_WEIGHT_UOM &&
        skusSelected.value.unit_amount &&
        skusSelected.value.uom
        ? skusSelected.value.unit_amount + ' ' + skusSelected.value.uom
        : '';
    });
    const colorGrayQuoteBtn = computed(() => {
      return !allowAttach.value ? true : false;
    });
    const priceKgBelowTotalPrice = computed(() => {
      return `${
        skusSelected.value.priced_by === QUOTED_PRICED_BY.WEIGHT
          ? skusSelected.value?.display_unit_price_per_weight
          : priceFormatter(
              currencySymbol.value,
              displayPrice(skusSelected.value?.price / skusSelected.value?.unit_amount)
            )
      } /${
        skusSelected.value.is_order_by_weight ||
        skusSelected.value.is_catch_weight ||
        skusSelected.value.priced_by === QUOTED_PRICED_BY.WEIGHT
          ? DEFAULT_WEIGHT_UOM
          : skusSelected.value.uom
      }`;
    });
    const disabledAddQuantity = computed(() => {
      if (isBackOrder.value || remainingQuantity.value === null) {
        return false;
      } else {
        return (
          orderQuantity.value >= MAX_INPUT ||
          orderQuantity.value >= remainingQuantity.value ||
          remainingQuantity.value <= 0 ||
          skusSelected.value.is_out_of_stock
        );
      }
    });

    const isBackOrder = computed(() => {
      if (skusSelected.value && skusSelected.value.sku_id !== 0) {
        return skusSelected.value.is_back_order;
      }
      return null;
    });
    const quantity = computed(() => {
      return (
        skusSelected.value && skusSelected.value.sku_id !== 0 && skusSelected.value.total_available_quantity
      );
    });
    const stock = computed(() => {
      return skusSelected.value && skusSelected.value.sku_id !== 0 && skusSelected.value.total_stock_location;
    });

    const isQuatityAndStock = computed(() => {
      let data = null;
      quantity.value === null && stock.value === null
        ? (data = null)
        : quantity.value === 0 && stock.value === 0
        ? (data = false)
        : quantity.value > 0 || stock.value > 0
        ? (data = true)
        : (data = null);
      return data;
    });

    const checkDisableAddToCart = computed(() => {
      return !isQuantityAMultipleOfIncrement({
        quantity: orderQuantity.value,
        increment: skusSelected.value?.increment_qty
      });
    });

    const isDisableFeedBack = computed(() => {
      return skusSelected.value && skusSelected.value.sku_id !== 0 ? false : true;
    });
    const isDirectPrice = computed(() => {
      return (
        skusSelected.value &&
        skusSelected.value.sku_id !== 0 &&
        skusSelected.value.direct_price > 0 &&
        skusSelected.value.direct_price === skusSelected.value.price
      );
    });
    const remainingQuantity = computed(() => {
      if (
        skusSelected.value.total_available_quantity < 0 ||
        skusSelected.value.total_available_quantity === null
      ) {
        return MAX_INPUT;
      } else {
        return skusSelected.value?.total_available_quantity ?? MAX_INPUT;
      }
    });
    const isSpecialPrice = computed(() => {
      return (
        skusSelected.value &&
        skusSelected.value.sku_id !== 0 &&
        skusSelected.value.direct_price > skusSelected.value.price
      );
    });
    const isShowDirectPriceQuoted = computed(() => {
      return skusSelected.value && skusSelected.value.sku_id !== 0 && skusSelected.value.direct_price > 0;
    });
    const showSimiliarProduct = computed(() => {
      return (
        isOutofStockRef.value &&
        (skusSelected.value?.sku_id !== 0 || skusSelected.value !== null) &&
        specsSelected.value?.length > 0
      );
    });
    const formattedWeight = computed(() => {
      return orderWeight.value;
    });
    const getSimiliarProductsData = computed(() => {
      return similiarProductItems.value;
    });
    const showBookingId = computed(() => {
      return item.value?.booking_order_id && item.value?.booking_order_id > 0;
    });
    const bookingDate = computed(() => {
      return formatDateMonthYear(item.value?.booking_order_date);
    });
    const isAllSkusOrderByWeight = computed(() => {
      return item.value.skusData?.every((item) => item.is_order_by_weight);
    });

    const isAllOrderCatchWeight = computed(() => {
      return item.value.skusData?.every((item) => item.is_catch_weight);
    });

    const isAllOrderPricedByWeight = computed(() => {
      return item.value.skusData?.every((item) => item.priced_by === QUOTED_PRICED_BY.WEIGHT);
    });

    const isCalculateByWeight = computed(() => {
      return isAllSkusOrderByWeight.value || isAllOrderCatchWeight.value || isAllOrderPricedByWeight.value;
    });

    const handleSkusSelectedChanges = async () => {
      await resetProductRecommend();
      if (skusSelected.value && skusSelected.value.sku_id !== 0) {
        // check out of stock skus
        skusSelected.value?.is_out_of_stock ? setOutOfStock(true) : setOutOfStock(false);
        if (isOutofStockRef.value) {
          checkOtherVariation.value = true;
          oosVariable.value.mainCategoryId = search.value.mainCategoryId;
          oosVariable.value.isSingleVariant = item.value.is_single_variant;
          oosVariable.value.productId = item.value.id;
          oosVariable.value.tenantId = item.value.tenant_id;
          oosVariable.value.buyerId = selectedCompany.value.id;
          await getSaleProductRecommend(oosVariable.value);
          setTimeout(() => {
            checkOtherVariation.value = false;
          }, 1000);
        }
        //logic ticket mer-207
        isChangeSkuFavorite.value
          ? (isChangeSkuFavorite.value = false)
          : setOrderQuantity(skusSelected.value.increment_qty);
      } else {
        setOutOfStock(false);
      }
    };

    onIonViewDidEnter(async () => {
      await clearQueryProductDetail();
      await getSaleProductDetail();
    });

    onIonViewWillLeave(() => {
      if (showSimiliarProduct.value) {
        similiarProductItems.value = null;
      }
    });

    const backToPreviousPage = () => {
      if (productIdAfterExplore.value) {
        router.replace(`/sale/main/product-detail/${productIdAfterExplore.value}`);
        setProductIdAfterExplore(null);
      } else {
        router.back();
      }
    };

    useBackButton(10, () => {
      backToPreviousPage();
    });

    watch(skusSelected, handleSkusSelectedChanges);
    watch(
      item,
      (productItemUpdated) => {
        item.value = productItemUpdated;
        reRenderProductImage.value++;
        updateSkusSelectedById(skusSelected.value?.sku_id);
        if (skusSelected.value.is_out_of_stock) {
          specsSelected.value.forEach((item) => {
            unCheckSpecItem(item);
          });
        }
      },
      { deep: true }
    );
    watch(isProductOutOfStockRef, async () => {
      await checkAndGetProductRecommend();
    });
    watch(oosList, (oosListUpdated) => {
      similiarProductItems.value = oosListUpdated?.oosList?.items;
      if (
        (showSimiliarProduct.value || isProductOutOfStockRef.value) &&
        similiarProductItems.value?.length > 0
      ) {
        // auto scroll to similar product list when the product is oos
        scrollToSimiliarProducts();
      }
    });

    return {
      storage,
      user,
      opacity,
      oosVariable,
      tenantClassLevel,
      orderQuantity,
      outOfStockQty,
      orderWeight,
      selectedCompany,
      specsInvolved,
      skuIdsInvolved,
      isOpenRef,
      setOpen,
      isShow,
      packagingSize,
      priceDiscountUomFn,
      priceFn,
      setTotalPricePerUomFn,
      setWeightFn,
      item,
      taggingSkuAttributes,
      isOpenSelectOtherVariant,
      isOpenPriceUpdated,
      latesNewPrice,
      productId,
      tenantId,
      changeSkusSelected,
      openToast,
      getProductDetail,
      route,
      router,
      refDescription,
      productDetailContent,
      similiarProduct,
      saleUpdatePrice,
      defaultSpecsSelected,
      updateSearch,
      getSaleProductRecommend,
      resetProductRecommend,
      oosUpdateSearch,
      getCartItems,
      addItemToCart,
      updatePriceWithoutReload,
      resetCategory,
      setProductIdAfterExplore,
      exploreRelatedCategories,
      backToPreviousPage,
      getSaleProductDetail,
      checkAndGetProductRecommend,
      handleDisplayShowMore,
      handleShowHeader,
      handleShowMore,
      onInputWeight,
      minusQuantity,
      addQuantity,
      orderQuantityChange,
      updateSkusSelectedById,
      showAlert,
      handleRefreshAndRefetchData,
      handleRefresh,
      detectConnection,
      showAlertErrPrice,
      handleGetCustomerDetail,
      setOtherVariantModal,
      handleCheckLatetestPrice,
      addToCart,
      getLatestPrice,
      setOpenModalPriceUpdate,
      updateLocalFavorite,
      specsSelected,
      skusSelected,
      unCheckSpecItem,
      checkSpecItem,
      selectSpec,
      checkNotAvailableSpecs,
      checkIsBuyAgainSpecs,
      multiSelectedSpecs,
      selectedSkuIds,
      getAllSkuIds,
      getAllowedSelectedSkusBySpecs,
      isAnySelectedIdIncluded,
      showOosAndBuyAgainStatus,
      scrollToSimiliarProducts,
      setSearchParamsBackToDefault,
      onSelectSimilarProduct,
      onExploreOtherProducts,
      openSocialSharing,
      selectedTab,
      arrowDownOutline,
      storefrontOutline,
      checkmarkOutline,
      paperPlaneOutline,
      informationCircleOutline,
      heart,
      remove,
      add,
      alertController,
      chevronBackOutline,
      removeCircleOutline,
      addCircleOutline,
      shareSocialOutline,
      chevronForwardOutline,
      isAddToCartLoading,
      checkOtherVariation,
      similiarProductItems,
      setOrderQuantity,
      isShowHeader,
      isShowMore,
      isDisplayShowMore,
      isOpenModalFeedBack,
      setOpenModalFeedBack,
      isChangeSkuFavorite,
      isOutofStockRef,
      setOutOfStock,
      Share,
      IMAGE_PATH,
      DEFAULT_PRODUCT_IMAGE,
      QUOTED_PRICED_BY,
      DEFAULT_WEIGHT_UOM,
      isOnline,
      isProductOutOfStockRef,
      setProductOutOfStock,
      store,
      reRenderProductImage,
      clearQueryProductDetail,
      isIosPlatform: isPlatform('ios'),
      attributesData,
      taggingSkusData,
      mappedTaggingSkusData,
      isOpenAttributesInfo,
      setOpenAttributesInfo,
      okButtons,
      itemComputed,
      search,
      status,
      error,
      list,
      listCache,
      CachesaleUpdatePrice,
      productIdAfterExplore,
      oosList,
      cart,
      currencySymbol,
      allProductOos,
      showPriceQuantity,
      showPriceWeight,
      weight,
      originPrice,
      price,
      getSpecsByKey,
      getSkusInfo,
      getSpecsOosByKey,
      getSpecsOosById,
      allowAttach,
      isDisabledButtonByWeight,
      disableOrderByWeight,
      colorGrayBtn,
      packaging_size,
      colorGrayQuoteBtn,
      priceKgBelowTotalPrice,
      disabledAddQuantity,
      isBackOrder,
      quantity,
      stock,
      isQuatityAndStock,
      checkDisableAddToCart,
      isDisableFeedBack,
      isDirectPrice,
      remainingQuantity,
      isSpecialPrice,
      isShowDirectPriceQuoted,
      showSimiliarProduct,
      formattedWeight,
      getSimiliarProductsData,
      showBookingId,
      bookingDate,
      formatDateMonthYear,
      isAllSkusOrderByWeight,
      isAllOrderCatchWeight,
      isAllOrderPricedByWeight,
      isCalculateByWeight,
      fromText
    };
  },

  async beforeRouteLeave(to, from, next) {
    const historyState = window.history.state;
    const prevHistoryPath = historyState?.back;
    // reset search params when user leave the product detail after add product to cart
    if (prevHistoryPath === '/sale/search-products' && to.path === '/sale/main/home') {
      await this.setSearchParamsBackToDefault();
    }
    next();
  }
});
</script>
<style src="./style.scss" lang="scss" scoped></style>
