<template>
  <ion-page>
    <ion-header mode="md" class="ion-no-border">
      <ion-toolbar class="px-4">
        <ion-label class="fs-4 fw-bold" color="dark" slot="start">{{
          $t('competitor_price_feedback')
        }}</ion-label>
        <ion-icon slot="end" :icon="closeOutline" @click="$emit('closeModal')"></ion-icon>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid class="ion-no-padding form-submit-information">
        <ion-text class="mb-3">{{ $t('submit_information_about_other_supplier') }}</ion-text>
        <ion-row>
          <ion-col size="12" class="ion-no-padding">
            <ion-label class="fs-2">{{ $t('product_name') }}</ion-label>
            <div>
              <p>{{ skusSelected.sku }}</p>
            </div>
          </ion-col>
          <ion-col size="12" class="ion-no-padding mb-3">
            <ion-label class="fs-2 market-price-title">{{ $t('competitor_price') }}</ion-label>
            <div class="d-flex align align-center market-price-input">
              <ion-text>{{ currencySymbol }}</ion-text>
              <ion-input
                class="pr-2"
                type="number"
                placeholder="0.00"
                v-model="item.total_competitor_price"
                inputmode="decimal"
                onkeydown="javascript: return event.keyCode == 69 ? false : true"
                @ionChange="priceChange"
              ></ion-input>
            </div>
          </ion-col>
          <ion-col size="12" class="ion-no-padding mb-3">
            <ion-label class="fs-2">{{ $t('accountPage.supplier_name') }}</ion-label>
            <div>
              <ion-input
                type="text"
                v-model="item.supplier_name"
                :placeholder="$t('input_competitor_name')"
              ></ion-input>
            </div>
          </ion-col>
          <ion-col size="12" class="ion-no-padding my-1">
            <ion-label class="fs-2">{{ $t('other_information') }}</ion-label>
            <div>
              <ion-input
                type="text"
                v-model="item.other_infomation"
                @ionChange="otherInfoChange"
                :placeholder="$t('other_info_competitor')"
              ></ion-input>
              <ion-text v-if="errOtherInfo" class="fs-2" color="danger">{{
                $t('other_info_error')
              }}</ion-text>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid class="ion-no-padding mb-3">
        <ion-row class="my-1" v-if="nameFile">
          <div class="form-image" v-for="(item, idx) in fileDisplayed" :key="idx">
            <div class="row">
              <div class="title">
                <img class="ml-1" :src="item.image" />
                <ion-label class="ml-1">{{ item.name.substring(0, 10) }}...</ion-label>
              </div>
              <div class="form-btn">
                <ion-button size="small" @click="handleDeleteFile(idx)" color="danger">{{
                  $t('delete')
                }}</ion-button>
                <ion-button size="small" @click="handleChangeFile(item)">{{ $t('change') }}</ion-button>
              </div>
            </div>
            <div v-if="item.isReachMaxSize" class="margin-justify">
              <ion-text color="danger">{{ $t('file_upload_error') }}</ion-text>
            </div>
          </div>
        </ion-row>
      </ion-grid>
      <ion-input
        placeholder=""
        type="file"
        accept="image/*"
        capture="camera"
        @ionInput="onPhotoChanged"
        ref="photoInputRef"
        class="d-none"
      >
      </ion-input>
      <ion-input
        placeholder=""
        type="file"
        accept=".xlsx,.xls,.pdf"
        @ionInput="onDocumentChanged"
        ref="documentInputRef"
        class="d-none"
      >
      </ion-input>
    </ion-content>
    <ion-action-sheet
      mode="ios"
      :is-open="isOpenRef"
      css-class="my-custom-class"
      :buttons="buttons"
      @didDismiss="setOpen(false)"
    >
    </ion-action-sheet>
    <!-- Loading  -->
    <ion-loading
      :is-open="isLoading"
      cssClass="custom-loading"
      message=""
      spinner="crescent"
      @didDismiss="setOpenLoading(false)"
    >
    </ion-loading>
    <ion-toast
      :is-open="isOpenToast"
      mode="ios"
      color="dark"
      :message="message"
      :duration="2000"
      position="top"
    >
    </ion-toast>

    <ion-footer mode="md" class="ion-no-border">
      <ion-toolbar>
        <ion-row class="form-submit">
          <ion-col size="12">
            <ion-button expand="block" fill="outline" @click="setOpen(true)"
              ><ion-icon :icon="addOutline"></ion-icon>{{ $t('add_file') }}</ion-button
            >
          </ion-col>
          <ion-col size="12">
            <ion-button
              expand="block"
              :color="isDiableSendFeedback ? 'medium' : 'primary'"
              :disabled="isDiableSendFeedback"
              @click="handleSendFeedBack"
              >{{ $t('send_feedback') }}</ion-button
            >
          </ion-col>
        </ion-row>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import excel from '@/assets/images/newdesign/excel.png';
import pdf from '@/assets/images/newdesign/pdf.jpg';
import { apolloClient } from '@/main';
import { saleAddPriceFeedback } from '@/modules/sale/services/graphql';
import { fileToBase64 } from '@/modules/sale/services/libs/image';
import { errorAlert } from '@/utils/alert';
import { addOutline, closeOutline } from 'ionicons/icons';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
export default {
  props: {
    currencySymbol: {
      type: String,
      default: ''
    },
    skusSelected: {
      type: Object,
      default: () => {}
    },
    selectedCompany: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['closeModal'],
  setup(props, { emit }) {
    const isLoading = ref(false);
    const setOpenLoading = (state) => (isLoading.value = state);
    const isOpenRef = ref(false);
    const errOtherInfo = ref(false);
    const setOpen = (state) => (isOpenRef.value = state);
    const reRender = ref(1);
    const isOpenToast = ref(false);
    const message = ref('');
    const { t } = useI18n();
    const imageUrl = ref('');
    const nameFile = ref('');
    const fileClassify = ref('');
    const fileDisplayed = ref([]);

    const fileChanges = ref(false);
    const maxFileSize = ref(process.env.VUE_APP_MAX_FILE_UPLOAD_SIZE);

    const photoInputRef = ref(null);
    const documentInputRef = ref(null);

    const triggerUploadImage = () => {
      const inputElement = photoInputRef.value.$el.querySelector('input');
      if (inputElement) {
        inputElement.click();
      }
    };

    const triggerUploadDocument = () => {
      const inputElement = documentInputRef.value.$el.querySelector('input');
      if (inputElement) {
        inputElement.click();
      }
    };

    const item = ref({
      sku_id: null,
      customer_id: null,
      total_competitor_price: null,
      supplier_name: null,
      other_infomation: null,
      documents: []
    });
    const buttons = ref([
      {
        text: t('open_document'),
        handler: () => {
          triggerUploadDocument();
        }
      },
      {
        text: t('photo'),
        handler: () => {
          triggerUploadImage();
        }
      },
      {
        text: t('cancel'),
        role: 'cancel'
      }
    ]);

    const fileSize = (size) => {
      return size / 1024 ** 2;
    };
    const priceChange = (event) => {
      let value = (event.target.value = event.target.value.replace('-', ''));
      let pattern = /(\d+\.\d\d)/g;
      let regex = pattern.exec(value);
      let maxLength = /(\d{0,18})/g.exec(value);
      if (maxLength.input.length > 18) {
        event.target.value = maxLength[1];
      }
      if (regex) {
        event.target.value = parseFloat(regex[1]);
      }
    };
    const otherInfoChange = (event) => {
      let value = event.target.value;
      if (value.length >= 255) {
        errOtherInfo.value = true;
      } else {
        errOtherInfo.value = false;
      }
    };

    const handleChangeFile = (file) => {
      fileChanges.value = file;
      fileClassify.value === 'document' ? triggerUploadDocument() : triggerUploadImage();
    };
    const handleDeleteFile = (idx) => {
      item.value.documents.splice(idx, 1);
      fileDisplayed.value.splice(idx, 1);
    };
    const handleOpenLoading = async (params) => {
      reRender.value++;
      isLoading.value = params;
    };
    const handleOpenToast = async (params) => {
      reRender.value++;
      isOpenToast.value = params;
    };
    const handleSendFeedBack = async () => {
      try {
        await handleOpenLoading(true);
        await apolloClient.mutate({
          mutation: saleAddPriceFeedback,
          variables: {
            item: item.value
          }
        });
        message.value = t('feedback');
        await handleOpenLoading(false);
        await handleOpenToast(true);
        setTimeout(() => {
          emit('closeModal');
        }, 500);
      } catch (e) {
        await handleOpenLoading(false);
        errorAlert(e.message);
      }
    };

    const onPhotoChanged = async (event) => {
      const files = event.target.querySelector('input').files;
      if (!files.length) return;
      if (!files[0].type.includes('image')) return;
      const findExistingFile = fileDisplayed.value.findIndex((item) => item.name === fileChanges.value.name);
      const fileBase64 = await fileToBase64(files[0]);
      imageUrl.value = fileBase64;
      nameFile.value = files[0].name;
      fileClassify.value = 'image';
      const size = fileSize(files[0].size);
      const file = {
        name: files[0].name,
        image: imageUrl.value,
        isReachMaxSize: size > maxFileSize.value ? true : false
      };
      if (files.length > 0) {
        if (findExistingFile !== -1) {
          fileDisplayed.value.splice(findExistingFile, 1);
          item.value.documents.splice(findExistingFile, 1);
          fileDisplayed.value = [file, ...fileDisplayed.value];
          item.value.documents.unshift(fileBase64);
        } else {
          fileDisplayed.value = [...fileDisplayed.value, file];
          item.value.documents.push(fileBase64);
        }
      }
    };
    const onDocumentChanged = async (event) => {
      const files = event.target.querySelector('input').files;
      if (!files.length) return;
      const findExistingFile = fileDisplayed.value.findIndex((item) => item.name === fileChanges.value.name);
      const fileBase64 = await fileToBase64(files[0]);
      const fileName = files[0].name.slice(-1);
      fileName === 'x' || fileName === 's' ? (imageUrl.value = excel) : (imageUrl.value = pdf);
      nameFile.value = files[0].name;
      fileClassify.value = 'document';
      const size = fileSize(files[0].size);
      const file = {
        name: files[0].name,
        image: imageUrl.value,
        isReachMaxSize: size > maxFileSize.value ? true : false
      };
      if (files.length > 0) {
        if (findExistingFile !== -1) {
          fileDisplayed.value.splice(findExistingFile, 1);
          item.value.documents.splice(findExistingFile, 1);
          fileDisplayed.value = [file, ...fileDisplayed.value];
          item.value.documents.unshift(fileBase64);
        } else {
          fileDisplayed.value = [...fileDisplayed.value, file];
          item.value.documents.push(fileBase64);
        }
      }
    };

    onMounted(() => {
      item.value.sku_id = props.skusSelected.sku_id;
      item.value.customer_id = props.selectedCompany.id;
    });

    // computed
    const isDiableSendFeedback = computed(() => {
      return !item.value.total_competitor_price || !item.value.supplier_name ? true : false;
    });
    return {
      closeOutline,
      addOutline,
      isOpenRef,
      setOpen,
      imageUrl,
      nameFile,
      fileClassify,
      fileDisplayed,
      fileChanges,
      maxFileSize,
      photoInputRef,
      documentInputRef,
      triggerUploadImage,
      triggerUploadDocument,
      item,
      buttons,
      fileSize,
      excel,
      pdf,
      isLoading,
      reRender,
      isOpenToast,
      message,
      priceChange,
      setOpenLoading,
      otherInfoChange,
      errOtherInfo,
      errorAlert,
      handleChangeFile,
      handleDeleteFile,
      handleOpenLoading,
      handleOpenToast,
      handleSendFeedBack,
      onPhotoChanged,
      onDocumentChanged,
      isDiableSendFeedback
    };
  }
};
</script>

<style src="./style.scss" lang="scss" scoped>
ion-content::part(scroll) {
  overflow: hidden;
}
</style>
