<template>
  <ion-page>
    <ion-header mode="md" class="ion-no-border px-2 pt-3">
      <ion-toolbar>
        <div class="variant-title" slot="start">{{ $t('select_variation') }}</div>
        <ion-icon
          class="close-modal"
          slot="end"
          :icon="closeOutline"
          @click="closeSelectOtherVariant"
        ></ion-icon>
      </ion-toolbar>
    </ion-header>
    <div class="line-grey"></div>
    <ion-content>
      <ion-grid>
        <ion-row class="d-flex px-2 py-2">
          <ion-col class="ion-no-padding align-start mt-3">
            <ion-label class="label-variation" color="grey">{{ $t('available_variation') }}</ion-label>
          </ion-col>
          <ion-col size="7" class="ion-no-padding d-flex flex-column align-end">
            <ion-row class="ion-align-items-end">
              <ion-text class="quantity-black">{{ $t('quantity') }}:</ion-text>
              <ion-text class="quantity ml-1" :color="isQuatityAndStock ? 'primary' : 'grey'">{{
                specsOosSelected.length === 0 || isQuatityAndStock === null
                  ? '-'
                  : !isQuatityAndStock
                  ? '0'
                  : `${+quantity > 0 ? quantity : 0}` +
                    ' ' +
                    $t('in') +
                    ' ' +
                    stock +
                    ' ' +
                    $t('stock_locations')
              }}</ion-text>
            </ion-row>

            <ion-row class="ion-align-items-end backorder">
              <ion-text class="quantity-black">{{ $t('back_order') }}:</ion-text>
              <ion-text class="quantity mt-0 ml-1" :color="isBackOrder === null ? 'grey' : 'primary'">{{
                specsOosSelected.length === 0 || isBackOrder === null
                  ? '-'
                  : isBackOrder
                  ? $t('Available')
                  : $t('unavailable')
              }}</ion-text>
            </ion-row>
          </ion-col>
        </ion-row>
        <div class="line-grey"></div>
        <ion-row class="mt-2 mx-2">
          <ion-col
            class="ion-no-padding mt-1"
            size="12"
            v-for="(specs, keyName) in getSpecsOosByKey"
            :key="keyName"
          >
            <ion-row>
              <ion-label class="label-keyname">{{ keyName }} </ion-label>
            </ion-row>
            <div class="d-flex flex-wrap mt-1">
              <div v-for="spec in specs" :key="spec.value" class="form-btn">
                <ion-button
                  mode="ios"
                  v-if="specsOosSelected.includes(spec.value)"
                  fill="outline"
                  class="btn-primary"
                  @click="unCheckOosSpecItem(spec.value)"
                >
                  {{ spec.value }}
                </ion-button>
                <ion-button
                  mode="ios"
                  class="btn-disable"
                  v-else-if="specsOosInvolved.length > 0 && !specsOosInvolved.includes(spec.value)"
                  fill="outline"
                  disabled
                >
                  {{ spec.value }}
                </ion-button>
                <ion-button
                  mode="ios"
                  v-else
                  fill="outline"
                  class="btn-grey"
                  @click="checkOosSpecItem(spec.value)"
                >
                  {{ spec.value }}
                </ion-button>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <div class="bg-gray"></div>
    <ion-footer class="ion-no-border">
      <ion-toolbar class="bg-white ion-no-padding">
        <div>
          <ion-grid class="ion-no-padding" v-if="allowAttach">
            <ion-row class="footer-input" :class="{ 'mb-3': !isDisableAddButton }">
              <ion-col size="6" class="pl-4 mt-2 mb-1">
                <div>
                  <ion-label class="label-total-price">{{ $t('total_price') }}</ion-label>
                </div>
                <div>
                  <ion-label v-if="specsOosSelected.length >= 1" class="label-sum-price">
                    <span v-if="variantSelected?.is_order_by_weight">
                      {{ showPriceWeight }}
                    </span>
                    <span v-else>
                      {{ showPriceQuantity }}
                    </span>
                  </ion-label>
                  <ion-label v-else class="label-sum-price">-</ion-label>
                </div>
                <div>
                  <ion-label v-if="specsOosSelected.length >= 1" class="label-price-kg">{{
                    priceKgBelowTotalPrice
                  }}</ion-label>
                  <ion-label v-else class="label-price-kg">-</ion-label>
                </div>
              </ion-col>
              <ion-col size="6" class="d-flex align-items-center pr-4 mt-2 mb-1">
                <div v-if="variantSelected?.is_order_by_weight" class="d-flex align-self-center">
                  <ion-input
                    type="number"
                    class="input-weight"
                    :value="formattedWeight"
                    @ionInput="onInputWeight"
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || (event.target.value.indexOf('.') === -1 && event.charCode === 46)"
                  />
                  <span class="ml-1 mt-2">KG</span>
                </div>
                <div class="d-flex cnt-weight h-100 form-input" v-else>
                  <div class="align-self-center text-center">
                    <div v-if="specsOosSelected.length >= 1" class="cnt-icon-q mr-2" @click="minusQuantity">
                      <ion-icon :icon="remove"></ion-icon>
                    </div>
                    <div v-else class="cnt-icon-q-outline mr-2" @click="minusQuantity">
                      <ion-icon class="icon-q" :icon="removeCircleOutline"></ion-icon>
                    </div>
                  </div>
                  <div v-if="specsOosSelected.length >= 1" size="5" class="align-self-center text-center">
                    <input
                      v-model.number="orderQuantity"
                      type="number"
                      maxlength="4"
                      @input="orderQuantityChange"
                      class="input-weight"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                    />
                  </div>
                  <div v-else size="5" class="align-self-center text-center">
                    <ion-input readonly v-model.number="outOfStockQty" type="text" class="input-oos" />
                  </div>

                  <div class="align-self-center text-center">
                    <div
                      v-if="specsOosSelected.length >= 1"
                      class="ml-2"
                      :class="{
                        'cnt-icon-q-disabled': disabledAddQuantity,
                        'cnt-icon-q': !disabledAddQuantity
                      }"
                      @click="addQuantity"
                    >
                      <ion-icon class="icon-q" :icon="add"></ion-icon>
                    </div>
                    <div v-else class="cnt-icon-q-outline ml-2" @click="addQuantity">
                      <ion-icon :icon="addCircleOutline"></ion-icon>
                    </div>
                  </div>
                </div>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="7" />
              <ion-col v-if="isDisableAddButton" size="5" class="ion-no-padding">
                <ion-text color="danger">{{
                  $t('soldInUnits', { number: variantSelected.increment_qty })
                }}</ion-text>
              </ion-col>
            </ion-row>
          </ion-grid>
          <div class="d-flex align-center ml-2 mb-2">
            <ion-button
              v-if="!showBookingId"
              fill="outline"
              tabindex="-1"
              class="btn-add-to-cart mr-0"
              :disabled="!allowAttach || specsOosSelected.length < 1"
              :color="specsOosSelected.length < 1 ? 'medium' : 'primary'"
              expand="block"
              @click="setOpen(true)"
            >
              {{ $t('quote_price') }}
            </ion-button>
            <!-- add to cart -->
            <div class="flex-grow-1 btn-add-to-card">
              <div>
                <ion-button
                  tabindex="-1"
                  class="btn-add-to-cart ml-2 mr-0"
                  fill="solid"
                  :disabled="isDisableAddButton || !allowAttach || disableOrderByWeight || isDisableBtn"
                  :color="!colorGrayBtn ? 'primary' : 'medium'"
                  expand="block"
                  @click="addToCart"
                >
                  <span v-if="!isAddToCartLoading">{{
                    allowAttach ? $t('add_to_cart') : $t('select_variation')
                  }}</span>
                  <ion-spinner name="crescent" v-else></ion-spinner>
                </ion-button>
              </div>
            </div>

            <!-- favorite -->
            <div>
              <btn-favorite
                :isFavorite="variantSelected?.is_favorite"
                :selectedCompany="selectedCompany"
                :skusSelected="variantSelected"
                :allowAttach="allowAttach"
                :specsOosSelected="specsOosSelected.length"
                @update-local-favorite="updateOosLocalFavorite"
              />
            </div>
          </div>
        </div>
      </ion-toolbar>
    </ion-footer>
    <ion-modal
      :is-open="isOpenRef"
      mode="md"
      css-class="modal-update-price quotation-modal"
      @didDismiss="setOpen(false)"
    >
      <QuotePrice
        :currencySymbol="currencySymbol"
        :selectedCompany="selectedCompany"
        :skusSelected="variantSelected"
        :weight="weight"
        :isShowDirectPriceQuoted="isShowDirectPriceQuoted"
        :item="itemData"
        :productId="productId"
        :skuIds="variantSelected?.sku_id"
        :tenantId="tenantId"
        @closeModal="setOpen(false)"
      />
    </ion-modal>
  </ion-page>
</template>
<script>
import { displayPrice, getTomorrow } from '@/modules/sale/services/libs/helper';
import { ACTIONS as ACTIONS_CART } from '@/modules/sale/store/cart/actions';
import { ACTIONS as ACTIONS_SALE } from '@/modules/sale/store/product/actions';
import { MUTATIONS } from '@/modules/sale/store/product/mutations';
import { INPUT_QUANTITY_BY_WEIGHT, MAX_INPUT } from '@/modules/shared/constants/';
import { isLargerMaxInput, isQuantityAMultipleOfIncrement } from '@/modules/shared/utils/';
import { setBadgeNumber } from '@/modules/shared/utils/badge';
import { priceFormatter } from '@/utils/';
import { alertController, toastController } from '@ionic/vue';
import {
  add,
  addCircleOutline,
  checkmarkOutline,
  chevronBackOutline,
  closeOutline,
  paperPlaneOutline,
  remove,
  removeCircleOutline
} from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { createNamespacedHelpers, useStore } from 'vuex';
import functions from '../utils';
import BtnFavorite from './BtnFavorite.vue';
import QuotePrice from './QuotePrice.vue';
const {
  mapGetters: mapGettersProduct,
  mapActions: mapActionsProduct,
  mapMutations: mapMutationsProduct
} = createNamespacedHelpers('sale/product');
const { mapGetters: mapGettersCart, mapActions: mapActionsCart } = createNamespacedHelpers('sale/cart');

export default defineComponent({
  components: {
    BtnFavorite,
    QuotePrice
  },
  props: [
    'item',
    'getSpecsOosByKey',
    'getSpecsOosById',
    'selectedCompany',
    'user',
    'oosList',
    'currencySymbol',
    'productId',
    'tenantId'
  ],
  setup(props) {
    const store = useStore();
    const orderQuantity = ref(1);
    const outOfStockQty = ref(0);
    const orderWeight = ref(null);
    const variantSelected = ref(null);
    const specsOosSelected = ref([]);
    const specsOosInvolved = ref([]);
    const isAddToCartLoading = ref(false);
    const itemData = ref(props.item);
    const isOpenRef = ref(false);
    const setOpen = (state) => {
      isOpenRef.value = state;
    };
    const changeSkusOosSelected = (state = { sku_id: 0 }) => {
      variantSelected.value = state;
      orderWeight.value = state.weight ? state.weight.toFixed(2) : 0;
      if (variantSelected.value && variantSelected.value?.sku_id !== 0) {
        orderQuantity.value = variantSelected.value?.increment_qty;
      }
    };
    const openToast = async (message, color = 'primary', position = 'top') => {
      const toast = await toastController.create({
        message,
        position,
        color,
        duration: 2000
      });
      return toast.present();
    };
    return {
      //icons
      closeOutline,
      checkmarkOutline,
      removeCircleOutline,
      addCircleOutline,
      chevronBackOutline,
      paperPlaneOutline,
      remove,
      add,

      //variable
      specsOosSelected,
      specsOosInvolved,
      outOfStockQty,
      orderQuantity,
      orderWeight,
      variantSelected,
      isAddToCartLoading,
      isOpenRef,
      itemData,

      //function,
      changeSkusOosSelected,
      openToast,
      setOpen,
      store
    };
  },

  mounted() {
    this.defaultOosSpecsSelected();
    this.orderWeight = this.variantSelected ? this.variantSelected.weight.toFixed(2) : 0;
  },

  computed: {
    ...mapGettersProduct(['item', 'search']),
    ...mapGettersCart(['cart']),
    isSample() {
      return this.search.isSample;
    },
    weight() {
      return this.variantSelected ? this.variantSelected.weight : 0;
    },
    isShowDirectPriceQuoted() {
      return (
        this.variantSelected && this.variantSelected.sku_id !== 0 && this.variantSelected.direct_price > 0
      );
    },
    quantity() {
      return (
        this.variantSelected &&
        this.variantSelected.sku_id !== 0 &&
        this.variantSelected.total_available_quantity
      );
    },
    stock() {
      return (
        this.variantSelected && this.variantSelected.sku_id !== 0 && this.variantSelected.total_stock_location
      );
    },

    isQuatityAndStock() {
      let data = null;
      this.quantity === null && this.stock === null
        ? (data = null)
        : this.quantity === 0 && this.stock === 0
        ? (data = false)
        : this.quantity > 0 || this.stock > 0
        ? (data = true)
        : (data = null);
      return data;
    },
    showPriceWeight() {
      return this.variantSelected
        ? priceFormatter(
            this.currencySymbol,
            displayPrice(
              (this.variantSelected.price / this.variantSelected.weight) * Number(this.orderWeight)
            )
          )
        : priceFormatter(this.currencySymbol, displayPrice(0));
    },

    showPriceQuantity() {
      return this.variantSelected
        ? priceFormatter(this.currencySymbol, displayPrice(this.variantSelected.price * this.orderQuantity))
        : priceFormatter(this.currencySymbol, displayPrice(0));
    },

    priceKgBelowTotalPrice() {
      return `${priceFormatter(
        this.currencySymbol,
        displayPrice(this.variantSelected?.price / this.variantSelected?.weight)
      )}/kg`;
    },
    remainingQuantity() {
      if (
        this.variantSelected.total_available_quantity < 0 ||
        this.variantSelected.total_available_quantity === null
      ) {
        return MAX_INPUT;
      } else {
        return this.variantSelected?.total_available_quantity ?? MAX_INPUT;
      }
    },
    isDirectPrice() {
      return (
        this.variantSelected &&
        this.variantSelected.sku_id !== 0 &&
        this.variantSelected.direct_price > 0 &&
        !this.variantSelected.is_special_price
      );
    },
    isDisableAddButton() {
      return !isQuantityAMultipleOfIncrement({
        quantity: this.orderQuantity,
        increment: this.variantSelected?.increment_qty
      });
    },
    isDisableBtn() {
      return !this.variantSelected?.is_order_by_weight ? this.orderQuantity <= 0 : this.orderWeight <= 0;
    },
    disabledAddQuantity() {
      if (this.isBackOrder || this.remainingQuantity === null) {
        return false;
      } else {
        return (
          this.orderQuantity >= MAX_INPUT ||
          this.orderQuantity >= this.remainingQuantity ||
          this.remainingQuantity <= 0 ||
          this.variantSelected.is_out_of_stock
        );
      }
    },
    isBackOrder() {
      if (this.variantSelected && this.variantSelected.sku_id !== 0) {
        return this.variantSelected.is_back_order;
      }
      return null;
    },
    allowAttach() {
      return this.variantSelected && this.variantSelected.sku_id !== 0;
    },
    disableOrderByWeight() {
      return this.variantSelected?.is_order_by_weight && this.variantSelected.total_available_quantity
        ? this.orderWeight > this.variantSelected.total_available_quantity
        : false;
    },
    colorGrayBtn() {
      return this.isDisableAddButton || !this.allowAttach || this.disableOrderByWeight || this.isDisableBtn;
    },
    formattedWeight() {
      return this.orderWeight;
    },
    showBookingId() {
      return this.item?.booking_order_id && this.item?.booking_order_id > 0;
    }
  },

  methods: {
    ...mapActionsCart([ACTIONS_CART.ADD_ITEM_TO_CART, ACTIONS_CART.GET_CART_ITEMS]),
    ...mapActionsProduct([ACTIONS_SALE.SALE_UPDATE_PRICE]),
    ...mapMutationsProduct([MUTATIONS.UPDATE_PRICE_WITHOUT_RELOAD]),
    closeSelectOtherVariant() {
      this.$emit('closeSelectOtherVariantModal');
      this.variantSelected = null;
      this.specsOosSelected = [];
      this.specsOosInvolved = [];
    },

    defaultOosSpecsSelected() {
      if (this.oosList?.oosList?.otherVariant.length === 1) {
        this.variantSelected = this.oosList?.oosList?.otherVariant[0];
        this.changeSkusOosSelected({ ...this.oosList?.oosList?.otherVariant[0], image: this.item?.image });
        const data = this.oosList?.oosList?.otherVariant[0].specs;
        const parseSpecs = JSON.parse(data);
        this.specsOosSelected = Object.values(parseSpecs);
        this.specsOosInvolved = Object.values(parseSpecs);
      }
    },

    async unCheckOosSpecItem(spec) {
      const list = this.getSpecsOosById;
      const index = this.specsOosSelected.indexOf(spec);
      // this.orderQuantity = 1;
      if (index !== -1) {
        // remove 1 spec -> length - 1
        this.specsOosSelected.splice(index, 1);
        this.specsOosInvolved = functions.getSpecsInvolvedByOosSpecs(this.specsOosSelected, list);

        // check if specsInvolved lenght = specsSelected length -> clear specsSelected
        if (this.specsOosSelected.length + 1 === this.specsOosInvolved.length) {
          this.specsOosSelected = [];
          this.specsOosInvolved = functions.getSpecsInvolvedByOosSpecs(this.specsOosSelected, list);
        }
      }
      this.updateOosSkusSelectedById(0);
    },

    checkOosSpecItem(value) {
      const specs = [...this.specsOosSelected, value];
      const list = this.getSpecsOosById;
      const { skuIds, specsOosSelected } = functions.findSkuFromOosSpecs(specs, list);

      if (skuIds.length === 1) {
        this.updateOosSkusSelectedById(skuIds[0]);
        this.specsOosSelected = specsOosSelected;
        this.specsOosInvolved = specsOosSelected;
      } else {
        this.specsOosSelected = specs;
        this.specsOosInvolved = functions.getSpecsInvolvedByOosSpecs(specs, list);
      }
    },

    updateOosSkusSelectedById(id) {
      if (id === 0) {
        this.changeSkusOosSelected();
      } else {
        const index = this.oosList?.oosList?.otherVariant.findIndex((skus) => skus.sku_id == id);
        let data = {};
        if (index !== -1) {
          data = {
            ...this.oosList?.oosList?.otherVariant[index],
            image: this.item?.image
          };
        }
        this.changeSkusOosSelected(data);
      }
    },

    minusQuantity() {
      const quantity = Number(this.orderQuantity) || 0;
      const increment = Number(this.variantSelected.increment_qty);

      if (quantity <= increment) {
        this.orderQuantity = 0;
      } else if (
        isQuantityAMultipleOfIncrement({
          quantity: quantity,
          increment: increment
        })
      ) {
        this.orderQuantity -= increment;
      } else {
        this.orderQuantity -= quantity % increment;
      }
    },

    addQuantity() {
      const quantity = Number(this.orderQuantity) || 0;
      const increment = Number(this.variantSelected.increment_qty);
      if (isLargerMaxInput(quantity + increment)) {
        this.orderQuantity = MAX_INPUT;
      } else {
        if (!this.isBackOrder) {
          if (quantity < this.remainingQuantity) {
            this.orderQuantity = quantity + increment - (quantity % increment);
          } else {
            this.openToast(this.$t('out_of_max_quantity', { quantity: this.remainingQuantity }), 'danger');
            this.orderQuantity = this.remainingQuantity;
          }
        } else {
          this.orderQuantity = quantity + increment - (quantity % increment);
        }
      }
    },

    orderQuantityChange() {
      // process check input value
      this.orderQuantity = Number(`${this.orderQuantity}`.replace(/[^\d]/g, ''));

      if (isLargerMaxInput(this.orderQuantity)) {
        this.orderQuantity = MAX_INPUT;
      } else {
        if (!this.isBackOrder && this.orderQuantity > this.remainingQuantity) {
          const increment = Number(this.variantSelected.increment_qty);
          this.openToast(this.$t('out_of_max_quantity', { quantity: this.remainingQuantity }), 'danger');
          this.orderQuantity = this.remainingQuantity - (this.remainingQuantity % increment);
        }
      }
    },

    async showAlert({ header, message }) {
      const alert = await alertController.create({
        mode: 'ios',
        header,
        message,
        buttons: [this.$t('close')]
      });
      setTimeout(async () => await alert.present(), 200);
    },

    onInputWeight: function (event) {
      const maxWeight = 999999.99;
      let value = event.target.value;
      const decimalIndex = value.indexOf('.');
      if (decimalIndex !== -1 && value.slice(decimalIndex + 1).length > 2) {
        // truncate to two decimal places
        value = Math.trunc(parseFloat(value) * 100) / 100;
        event.target.value = value;
      }
      this.orderWeight = value;
      if (!this.isBackOrder && this.orderWeight > this.remainingQuantity) {
        this.orderWeight = this.remainingQuantity.toFixed(2);
        event.target.value = this.orderWeight;
        this.openToast(this.$t('out_of_max_weight', { weight: this.remainingQuantity.toFixed(2) }), 'danger');
      } else {
        if (this.formattedWeight > maxWeight) {
          this.openToast(this.$t('out_of_max_weight', { weight: maxWeight }), 'danger');
          this.orderWeight = maxWeight.toFixed(2);
          event.target.value = this.orderWeight;
        }
      }
    },

    updateOosLocalFavorite() {
      const newFavorite = !this.variantSelected.is_favorite;
      this.changeSkusOosSelected({
        ...this.variantSelected,
        is_favorite: newFavorite
      });

      this.oosList?.oosList?.otherVariant.forEach((skus) => {
        if (skus.sku_id == this.variantSelected.sku_id) {
          skus.is_favorite = newFavorite;
        }
      });
      //logic ticket mer-207
      this.$$emit('updateSkusfavorite');
      this.$emit('refreshFavoriteList');
    },

    // add item to cart
    async addToCart() {
      if (this.isAddToCartLoading) return false;
      this.isAddToCartLoading = true;
      const cart =
        this.cart && this.cart.items.length !== 0
          ? this.cart
          : {
              items: [],
              delivery_date: getTomorrow(),
              delivery_time: '',
              // description: '',
              // po_number: '',
              standalone: 0
            };
      const item = {
        sku_id: this.variantSelected.sku_id,
        is_favorite: this.variantSelected.is_favorite,
        quotation_status: this.variantSelected.quotation_status,
        supplier_id: this.selectedCompany.id
      };

      item.order_qty = this.variantSelected.is_order_by_weight
        ? INPUT_QUANTITY_BY_WEIGHT
        : this.orderQuantity;
      item.order_weight = this.orderWeight;
      item.quotation_status = this.variantSelected.quotation_status;
      item.sku_id = this.variantSelected.sku_id;
      item.is_favorite = this.variantSelected.is_favorite;
      item.selected = true;
      delete item.total_available_quantity;
      const params = {
        customer_id: this.selectedCompany.id,
        delivery_date: cart.delivery_date,
        delivery_time: cart.delivery_time,
        // description: cart.description,
        // po_number: cart.po_number,
        standalone: cart.standalone === 1 ? true : false,
        item
      };
      await this[ACTIONS_CART.ADD_ITEM_TO_CART](params);
      await setBadgeNumber(this.store, this.selectedCompany.id);
      this.closeSelectOtherVariant();
      this.openToast(this.$t('successfully_added_to_cart'));
      this.isAddToCartLoading = false;
      this.$router.replace({ path: '/sale/main/home' });
    }
  }
});
</script>
<style src="../style.scss" lang="scss" scoped></style>
