<template>
  <ion-page>
    <ion-header mode="md" class="ion-no-border">
      <ion-toolbar class="header-modal-update-price px-3">
        <ion-label class="fs-4 fw-bold" color="dark" slot="start">{{ $t('quote_price') }}</ion-label>
        <ion-icon slot="end" :icon="closeOutline" @click="closeQuotePrice"></ion-icon>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid class="mx-4 ion-no-padding mt-4">
        <div class="d-flex sku-title">
          <td-image
            class="img-update-price"
            :image="item?.image"
            :imagePath="IMAGE_PATH.PRODUCT"
            :imageDefault="DEFAULT_PRODUCT_IMAGE"
          ></td-image>
          <div class="sku-name ml-2">
            <ion-label class="label-tenant mt-2"> {{ item?.tenant?.tenant?.name }} </ion-label>
            <ion-label class="title mt-1">{{ skusUpdatePriceSelected.sku }}</ion-label>
            <div class="mt-1">
              <ion-label v-if="item?.halal === true" class="label-halal">{{
                $t('sync_state.halal')
              }}</ion-label>
              <ion-label v-if="item?.halal === false" class="label-non-halal">{{
                $t('sync_state.non_halal')
              }}</ion-label>
            </div>
          </div>
        </div>
        <div class="sku-info mt-2">
          <ion-row>
            <ion-col size="6" class="ion-no-padding">
              <ion-label>{{ $t('packaging_size') }}</ion-label>
            </ion-col>
            <ion-col size="6" class="ion-no-padding">
              <ion-label>{{ $t('weight') }}</ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="6" class="ion-no-padding">
              <ion-text>{{ packagingSize }}</ion-text>
            </ion-col>
            <ion-col size="6" class="ion-no-padding">
              <ion-text v-if="!skusSelected?.is_order_by_weight" class="mb-1">{{
                weight ? weight + 'kg' : ''
              }}</ion-text>
              <tooltip v-if="skusSelected?.is_catch_weight" />
              <ion-text v-if="skusSelected?.is_order_by_weight">{{ $t('order_by_weight') }}</ion-text>
            </ion-col>
          </ion-row>
        </div>
      </ion-grid>
      <div class="bg-gray my-1"></div>
      <ion-grid class="ion-no-padding mx-4 mt-1">
        <div v-if="!isShowDirectPriceQuoted">
          <div class="d-flex justify-space-between align-center limit-price mt-1">
            <ion-label>{{ $t('limit_price') }}</ion-label>
            <div>
              <ion-text v-if="!skusSelected?.is_order_by_weight"
                >{{ handleShowLimitPrice(skusUpdatePriceSelected.limit_price) }}&nbsp;</ion-text
              >
              <ion-text
                >{{ !skusSelected?.is_order_by_weight ? '(' : ''
                }}{{
                  handleShowPriceKg(
                    skusUpdatePriceSelected.limit_price,
                    uom === DEFAULT_WEIGHT_UOM ? weight : skusSelected?.unit_amount
                  )
                }}{{ !skusSelected?.is_order_by_weight ? ')' : '' }}</ion-text
              >
            </div>
          </div>
          <div class="d-flex justify-space-between align-center limit-price mt-1">
            <ion-label>{{ $t('last_quoted_price') }}</ion-label>
            <div>
              <ion-text v-if="!skusSelected?.is_order_by_weight">{{
                item?.date_quoted ? price + ' ' : '-'
              }}</ion-text>
              <ion-text
                >{{ !skusSelected?.is_order_by_weight ? '(' : ''
                }}{{
                  item?.date_quoted
                    ? handleShowPriceKg(
                        skusUpdatePriceSelected.price,
                        uom === DEFAULT_WEIGHT_UOM ? weight : skusSelected?.unit_amount
                      )
                    : ''
                }}{{ !skusSelected?.is_order_by_weight ? ')' : '' }}</ion-text
              >
            </div>
          </div>
        </div>
        <div v-else>
          <div class="d-flex justify-space-between align-center limit-price mt-1">
            <ion-label>{{ $t('direct_price') }}</ion-label>
            <div>
              <ion-text v-if="!skusSelected?.is_order_by_weight"
                >{{ handleShowLimitPrice(skusUpdatePriceSelected.direct_price) }}&nbsp;</ion-text
              >
              <ion-text
                >{{ !skusSelected?.is_order_by_weight ? '(' : ''
                }}{{
                  handleShowPriceKg(
                    skusUpdatePriceSelected.direct_price,
                    uom === DEFAULT_WEIGHT_UOM ? weight : skusSelected?.unit_amount
                  )
                }}{{ !skusSelected?.is_order_by_weight ? ')' : '' }}</ion-text
              >
            </div>
          </div>
          <div class="d-flex justify-space-between align-center limit-price mt-1">
            <ion-label>{{ $t('limit_price') }}</ion-label>
            <div>
              <ion-text v-if="!skusSelected?.is_order_by_weight"
                >{{ handleShowLimitPrice(skusUpdatePriceSelected.limit_price) }}&nbsp;</ion-text
              >
              <ion-text
                >{{ !skusSelected?.is_order_by_weight ? '(' : ''
                }}{{
                  handleShowPriceKg(
                    skusUpdatePriceSelected.limit_price,
                    uom === DEFAULT_WEIGHT_UOM ? weight : skusSelected?.unit_amount
                  )
                }}{{ !skusSelected?.is_order_by_weight ? ')' : '' }}</ion-text
              >
            </div>
          </div>
          <div class="d-flex justify-space-between align-center limit-price mt-1">
            <ion-label>{{ $t('last_quoted_price') }}</ion-label>
            <div>
              <ion-text v-if="!skusSelected?.is_order_by_weight"
                >{{
                  skusUpdatePriceSelected?.quotation_status === QUOTE_RECEIVED
                    ? handleShowLimitPrice(skusUpdatePriceSelected.price)
                    : '-.--'
                }}&nbsp;</ion-text
              >
              <ion-text
                >{{ !skusSelected?.is_order_by_weight ? '(' : ''
                }}{{
                  skusUpdatePriceSelected?.quotation_status === QUOTE_RECEIVED
                    ? handleShowPriceKg(
                        skusUpdatePriceSelected.price,
                        uom === DEFAULT_WEIGHT_UOM ? weight : skusSelected?.unit_amount
                      )
                    : '-.--'
                }}{{ !skusSelected?.is_order_by_weight ? ')' : '' }}</ion-text
              >
            </div>
          </div>
        </div>
        <div class="d-flex justify-space-between align-center total-new-price mt-1">
          <ion-label>{{ $t('total_new_price') }}</ion-label>
          <div v-if="!skusSelected?.is_order_by_weight">
            <ion-text class="total-price-orange">{{ priceUpdateStr }}</ion-text>
            <ion-text class="ml-1 unit-price-black">({{ unitPriceUpdateStr }})</ion-text>
          </div>
          <div v-else>
            <ion-text class="ml-1 total-price-orange">{{ unitPriceUpdateStr }}</ion-text>
          </div>
        </div>
      </ion-grid>
      <div class="bg-gray my-1"></div>
      <ion-grid class="ion-no-padding mt-3 new-price">
        <ion-label class="mx-4 mb-4 mt-2">{{ $t('set_new_price') }}:</ion-label>
        <ion-radio-group
          v-if="!skusSelected.is_order_by_weight && !skusSelected.is_catch_weight"
          class="ion-no-padding"
          :value="quotedTypeKey"
          mode="md"
        >
          <ion-row class="mt-4 mx-4 mb-2">
            <ion-col>
              <div class="d-flex flex-row align-center" @click="selectQuoteType(QUOTED_PRICED_BY.WEIGHT)">
                <ion-radio slot="start" :value="QUOTED_PRICED_BY.WEIGHT"></ion-radio>
                <ion-label class="ml-2">{{ $t('total_price') }}</ion-label>
              </div>
            </ion-col>
            <ion-col>
              <div class="d-flex flex-row align-center" @click="selectQuoteType(QUOTED_PRICED_BY.OOM)">
                <ion-radio slot="start" :value="QUOTED_PRICED_BY.OOM"></ion-radio>
                <ion-label class="ml-2">{{ $t('unit_price') }} </ion-label>
              </div>
            </ion-col>
          </ion-row>
        </ion-radio-group>
        <div
          :class="
            pricedByOom || skusSelected.is_order_by_weight || skusSelected.is_catch_weight
              ? 'd-flex flex-row justify-space-between'
              : ''
          "
          class="form-input align-center mt-2 mr-4"
        >
          <ion-item
            :class="
              quotedTypeKey === QUOTED_PRICED_BY.WEIGHT &&
              !skusSelected.is_order_by_weight &&
              !skusSelected.is_catch_weight
                ? ''
                : 'mr-1'
            "
            lines="inset"
            :detail="false"
          >
            <ion-label>{{ currencySymbol }}</ion-label>
            <ion-input
              v-model="price_kg"
              type="number"
              onkeydown="javascript: return event.keyCode == 69 ? false : true"
              placeholder="0.00"
              inputmode="decimal"
              :class="
                skusSelected.is_catch_weight || skusSelected.is_order_by_weight || pricedByOom
                  ? 'input-quote-item-kg'
                  : 'input-quote-item'
              "
              class="ion-text-right"
              @ionChange="setTwoDecimal"
            ></ion-input>
          </ion-item>
          <div v-if="skusSelected.is_catch_weight || skusSelected.is_order_by_weight">/{{ uom }}</div>
          <div v-if="pricedByOom" class="ml-2 select-uom d-flex flex-row" @click="openSelectUom(true)">
            <ion-text>/{{ uom }}</ion-text>
            <ion-icon :icon="isOpenUomRef ? chevronUpOutline : chevronDownOutline"></ion-icon>
          </div>
        </div>
      </ion-grid>
    </ion-content>
    <ion-footer class="ion-no-border">
      <ion-grid>
        <ion-row>
          <ion-col size="12" class="btn-submit my-1">
            <ion-button
              mode="md"
              :class="needRequestApproval ? 'btn-request-approve' : ''"
              :disabled="isDisableBtn"
              shape="round"
              expand="full"
              @click="saleUpdatePrice"
              >{{ !needRequestApproval ? $t('update_price') : $t('request_approve') }}</ion-button
            >
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
    <ion-modal
      ref="modal"
      :initial-breakpoint="1"
      :breakpoints="[0, 0.5, 1]"
      mode="ios"
      css-class="bottom-sheet-modal-sm"
      :is-open="isOpenUomRef"
      @didDismiss="openSelectUom(false)"
    >
      <ion-page>
        <ion-header mode="md" class="ion-no-border">
          <ion-toolbar class="header-modal-update-price px-3">
            <ion-label class="fs-4 fw-bold" color="dark" slot="start">{{ $t('qoted_by') }}</ion-label>
            <ion-icon slot="end" :icon="closeOutline" @click="openSelectUom(false)"></ion-icon>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <ion-list class="mr-4">
            <ion-radio-group mode="md" :value="uom">
              <ion-item @click="chooseUom(DEFAULT_WEIGHT_UOM)">
                <ion-label>/{{ DEFAULT_WEIGHT_UOM }}</ion-label>
                <ion-radio slot="start" :value="DEFAULT_WEIGHT_UOM"></ion-radio>
              </ion-item>

              <ion-item v-if="skusSelected.uom !== DEFAULT_WEIGHT_UOM" @click="chooseUom(skusSelected.uom)">
                <ion-label>/{{ skusSelected.uom }}</ion-label>
                <ion-radio slot="start" :value="skusSelected.uom"></ion-radio>
              </ion-item>
            </ion-radio-group>
          </ion-list>
        </ion-content>
      </ion-page>
    </ion-modal>
    <ion-loading
      mode="ios"
      :is-open="isOpenLoadingRef"
      cssClass="my-custom-class"
      :message="`${$t('please_wait')}. . .`"
      @didDismiss="setOpenLoading(false)"
    >
    </ion-loading>
  </ion-page>
</template>
<script>
import Tooltip from '@/components/molecules/tooltip/Tooltip.vue';
import { DEFAULT_PRODUCT_IMAGE, IMAGE_PATH } from '@/modules/b2b/constants';
import { displayPrice } from '@/modules/sale/services/libs/helper';
import { ACTIONS as ACTIONS_SALE } from '@/modules/sale/store/product/actions';
import { MUTATIONS } from '@/modules/sale/store/product/mutations';
import { DEFAULT_WEIGHT_UOM, QUOTED_PRICED_BY, QUOTE_RECEIVED } from '@/modules/shared/constants/';
import { useCheck } from '@/modules/shared/utils/';
import { priceFormatter } from '@/utils/';
import { alertController, toastController } from '@ionic/vue';
import { chevronDownOutline, chevronUpOutline, closeOutline } from 'ionicons/icons';
import { computed, defineComponent, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default defineComponent({
  components: {
    Tooltip
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    skusSelected: {
      type: Object,
      default: () => {}
    },
    selectedCompany: {
      type: Object,
      default: () => {}
    },
    weight: {
      type: Number,
      default: 0
    },
    currencySymbol: {
      type: String,
      default: ''
    },
    isShowDirectPriceQuoted: {
      type: Boolean,
      default: false
    },
    productId: {
      type: Number,
      default: 0
    },
    tenantId: {
      type: Number,
      default: 0
    },
    price: {
      type: String,
      default: ''
    }
  },
  emits: ['closeModal', 'onUpdateProductDetails'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const skusUpdatePriceSelected = ref(props.skusSelected);
    const price_kg = ref(null);
    const isDisableBtn = ref(true);
    const isNewBiggerPrice = ref(false);
    const isBlockQuotePrice = ref(false);
    const priceUpdateStr = ref('-.--');
    const unitPriceUpdateStr = ref(`${props.currencySymbol}0.00`);
    const priceUpdate = ref(0);
    const { checkWeightItem, combineTwoUnit, isItemKGAndNoneUnit } = useCheck();
    const quotedTypeKey = ref(
      !props.skusSelected.is_catch_weight && !props.skusSelected.is_order_by_weight
        ? QUOTED_PRICED_BY.OOM
        : props.skusSelected.priced_by
    );
    const uom = ref(
      props.skusSelected.is_order_by_weight ||
        props.skusSelected.is_catch_weight ||
        props.skusSelected.priced_by === QUOTED_PRICED_BY.WEIGHT
        ? DEFAULT_WEIGHT_UOM
        : props.skusSelected.uom
    );
    const selectQuoteType = (key) => {
      quotedTypeKey.value = key;
      if (key === QUOTED_PRICED_BY.WEIGHT) {
        uom.value =
          props.skusSelected.is_order_by_weight ||
          props.skusSelected.is_catch_weight ||
          props.skusSelected.priced_by === QUOTED_PRICED_BY.WEIGHT
            ? DEFAULT_WEIGHT_UOM
            : props.skusSelected.uom;
      }
    };
    const chooseUom = (selectedUom) => {
      uom.value = selectedUom;
    };
    const isOpenUomRef = ref(false);
    const openSelectUom = (params) => {
      isOpenUomRef.value = params;
    };
    const packagingSize = computed(() => {
      if (skusUpdatePriceSelected.value) {
        const { unit_amount, uom, unit_per_item, unit_per_item_uom } = skusUpdatePriceSelected.value;
        if (isItemKGAndNoneUnit(uom, unit_per_item)) {
          return '';
        }
        const itemsInProduct = checkWeightItem(unit_amount, uom);
        const unitsInItem = checkWeightItem(unit_per_item, unit_per_item_uom);
        return combineTwoUnit(itemsInProduct, unitsInItem);
      }
      return '';
    });
    const setTwoDecimal = (event) => {
      let value = event.target.value;
      value.length === 0 ? (isBlockQuotePrice.value = true) : (isBlockQuotePrice.value = false);
      let pattern = /(\d+\.\d\d)/g;
      let regex = pattern.exec(value);
      let maxLength = /(\d{0,18})/g.exec(value);
      if (maxLength.input.length > 18) {
        event.target.value = maxLength[1];
      }
      if (regex) {
        event.target.value = parseFloat(regex[1]);
      } else {
        return false;
      }
    };
    const openToast = async (message, mode, color = 'dark', position = 'bottom') => {
      const toast = await toastController.create({
        message,
        position,
        color,
        mode: 'ios',
        duration: 2000,
        cssClass: 'toastAfterHeader'
      });
      return toast.present();
    };
    const isOpenLoadingRef = ref(false);
    const setOpenLoading = async (state) => (isOpenLoadingRef.value = state);

    const updatePrice = (params) => store.dispatch('sale/product/' + ACTIONS_SALE.SALE_UPDATE_PRICE, params);
    const updatePriceWithoutReload = () =>
      store.commit('sale/product/' + MUTATIONS.UPDATE_PRICE_WITHOUT_RELOAD);

    const closeQuotePrice = () => {
      emit('closeModal');
    };
    const showAlert = async ({ header, message }) => {
      const alert = await alertController.create({
        mode: 'ios',
        header,
        message,
        buttons: [
          {
            text: t('close'),
            handler: () => {
              emit('closeModal');
            }
          }
        ]
      });
      setTimeout(async () => await alert.present(), 200);
    };

    const sendUpdatePrice = async () => {
      await setOpenLoading(true);
      await updatePrice({
        payload: {
          customerBuyerId: props.selectedCompany.id,
          skuId: skusUpdatePriceSelected.value
            ? skusUpdatePriceSelected.value.sku_id
            : props.item?.skusData[0].sku_id,
          tenantId: props.item?.tenant_id,
          unitPrice:
            quotedTypeKey.value === QUOTED_PRICED_BY.WEIGHT &&
            !props.skusSelected.is_order_by_weight &&
            !props.skusSelected.is_catch_weight
              ? price_kg.value / props.skusSelected.weight
              : +price_kg.value,
          originalPrice: +skusUpdatePriceSelected.value.price,
          price:
            quotedTypeKey.value === QUOTED_PRICED_BY.WEIGHT &&
            !props.skusSelected.is_order_by_weight &&
            !props.skusSelected.is_catch_weight
              ? +price_kg.value
              : +priceUpdate.value,
          category: listCache.value
        }
      });
      await setOpenLoading(false);
      if (status.value && !needRequestApproval.value) {
        await emit('onUpdateProductDetails');
        skusUpdatePriceSelected.value.price =
          quotedTypeKey.value === QUOTED_PRICED_BY.WEIGHT &&
          (!props.skusSelected.is_order_by_weight || props.skusSelected.is_catch_weight)
            ? price_kg.value
            : priceUpdate.value;
        openToast(t('price_updated') + '!');
        await updatePriceWithoutReload();
        emit('closeModal');
      } else {
        await showAlert({
          header: t('quotationPendingTitle'),
          message: t('quotationPendingContent')
        });
      }
    };
    const saleUpdatePrice = async () => {
      if (
        isBlockQuotePrice.value ||
        Math.sign(+priceUpdate.value) === -1 ||
        Math.sign(+price_kg.value) === -1
      ) {
        openToast(t('invalid_price'), 'danger');
        return;
      }

      if (
        skusUpdatePriceSelected.value.direct_price > 0 &&
        skusUpdatePriceSelected.value.direct_price <
          (quotedTypeKey.value === QUOTED_PRICED_BY.WEIGHT &&
          !props.skusSelected.is_order_by_weight &&
          !props.skusSelected.is_catch_weight
            ? price_kg.value
            : priceUpdate.value)
      ) {
        const alert = await alertController.create({
          mode: 'ios',
          header: t('quote_price_great_direct_price_title'),
          message: t('quote_price_great_direct_price_body'),
          backdropDismiss: false,
          buttons: [
            {
              text: t('cancel')
            },
            {
              text: t('quote_price_great_direct_price_agree'),
              handler: () => {
                sendUpdatePrice();
              }
            }
          ]
        });
        return alert.present();
      } else {
        sendUpdatePrice();
      }
    };
    const handleShowLimitPrice = (price) => {
      return `${priceFormatter(props.currencySymbol, displayPrice(price))}`;
    };
    const handleShowPriceKg = (price, packaging) => {
      return `${priceFormatter(props.currencySymbol, displayPrice(price / packaging))}/` + `${uom.value}`;
    };

    // computed
    const status = computed(() => store.getters['sale/product/status']);
    const error = computed(() => store.getters['sale/product/error']);
    const listCache = computed(() => store.getters['sale/product/listCache']);
    const isNotOrderCatchWeight = computed(() => {
      return !props.skusSelected.is_catch_weight && !props.skusSelected.is_order_by_weight;
    });
    const isCalculateByTotalPrice = computed(() => {
      return quotedTypeKey.value === QUOTED_PRICED_BY.WEIGHT && isNotOrderCatchWeight.value;
    });

    const pricedByOom = computed(() => {
      return quotedTypeKey.value === QUOTED_PRICED_BY.OOM && isNotOrderCatchWeight.value;
    });

    const needRequestApproval = computed(() => {
      const isQuotedByWeight = quotedTypeKey.value === QUOTED_PRICED_BY.WEIGHT;
      const isLimitPriceGreaterThanQuotedByTotalPrice =
        skusUpdatePriceSelected.value.limit_price > price_kg.value;

      if (isCalculateByTotalPrice.value || (isQuotedByWeight && isNotOrderCatchWeight.value)) {
        return isLimitPriceGreaterThanQuotedByTotalPrice;
      }

      if (isWeightCalculation.value) {
        return isNotOrderCatchWeight.value
          ? skusUpdatePriceSelected.value.limit_price > priceUpdate.value
          : skusUpdatePriceSelected.value.limit_price / skusUpdatePriceSelected.value.weight > price_kg.value;
      }

      return skusUpdatePriceSelected.value.limit_price > priceUpdate.value;
    });

    const isWeightCalculation = computed(() => {
      return (
        props.skusSelected.priced_by === QUOTED_PRICED_BY.WEIGHT ||
        props.skusSelected.is_catch_weight ||
        props.skusSelected.is_order_by_weight ||
        uom.value === DEFAULT_WEIGHT_UOM
      );
    });

    watch(price_kg, (newVal) => {
      if (newVal === '' || newVal.length === 0) {
        newVal = 0;
      }
      let newPrice =
        parseFloat(newVal) *
        parseFloat(
          isWeightCalculation.value && uom.value === DEFAULT_WEIGHT_UOM
            ? props.weight
            : props.skusSelected?.unit_amount
        );
      newPrice = parseFloat((Math.round(Number(newPrice) * 100) / 100).toFixed(2));
      priceUpdate.value = newPrice;
      priceUpdateStr.value = isNaN(newPrice)
        ? '-.--'
        : isCalculateByTotalPrice.value
        ? priceFormatter(
            props.currencySymbol,
            quotedTypeKey.value === QUOTED_PRICED_BY.OOM ? newPrice : price_kg.value
          )
        : priceFormatter(props.currencySymbol, newPrice);
      unitPriceUpdateStr.value = isCalculateByTotalPrice.value
        ? handleShowPriceKg(
            quotedTypeKey.value === QUOTED_PRICED_BY.OOM ? newPrice : price_kg.value,
            uom.value === DEFAULT_WEIGHT_UOM ? props.skusSelected.weight : props.skusSelected.unit_amount
          )
        : handleShowPriceKg(price_kg.value, 1);
      isNewBiggerPrice.value = newPrice >= skusUpdatePriceSelected.value?.limit_price;
    });

    watch(quotedTypeKey, (newVal) => {
      let newPrice =
        parseFloat(price_kg.value) *
        parseFloat(
          isWeightCalculation.value && uom.value === DEFAULT_WEIGHT_UOM
            ? props.weight
            : props.skusSelected?.unit_amount
        );
      newPrice = parseFloat((Math.round(Number(newPrice) * 100) / 100).toFixed(2));
      priceUpdate.value = newPrice;
      priceUpdateStr.value = isNaN(newPrice)
        ? '-.--'
        : newVal === QUOTED_PRICED_BY.WEIGHT &&
          !props.skusSelected.is_order_by_weight &&
          !props.skusSelected.is_catch_weight
        ? priceFormatter(props.currencySymbol, price_kg.value)
        : priceFormatter(props.currencySymbol, newPrice);
      unitPriceUpdateStr.value =
        newVal === QUOTED_PRICED_BY.WEIGHT &&
        !props.skusSelected.is_order_by_weight &&
        !props.skusSelected.is_catch_weight
          ? handleShowPriceKg(
              price_kg.value,
              uom.value === DEFAULT_WEIGHT_UOM ? props.skusSelected.weight : props.skusSelected.unit_amount
            )
          : handleShowPriceKg(price_kg.value, 1);
    });

    watch(priceUpdate, (newValPriceUpdate) => {
      if (isNaN(newValPriceUpdate) || newValPriceUpdate == 0 || price_kg.value === null) {
        isDisableBtn.value = true;
      } else {
        isDisableBtn.value = false;
      }
    });

    watch(uom, (newVal) => {
      let newPrice =
        parseFloat(price_kg.value) *
        parseFloat(
          isWeightCalculation.value && newVal === DEFAULT_WEIGHT_UOM
            ? props.weight
            : props.skusSelected?.unit_amount
        );
      newPrice = parseFloat((Math.round(Number(newPrice) * 100) / 100).toFixed(2));
      priceUpdate.value = newPrice;
      priceUpdateStr.value = isNaN(newPrice)
        ? '-.--'
        : newVal && isCalculateByTotalPrice.value
        ? priceFormatter(props.currencySymbol, price_kg.value)
        : priceFormatter(props.currencySymbol, newPrice);
      unitPriceUpdateStr.value = isCalculateByTotalPrice.value
        ? handleShowPriceKg(price_kg.value, props.skusSelected.weight)
        : handleShowPriceKg(price_kg.value, 1);
      isNewBiggerPrice.value = newPrice >= skusUpdatePriceSelected.value?.limit_price;
    });
    return {
      DEFAULT_WEIGHT_UOM,
      closeOutline,
      chevronDownOutline,
      chevronUpOutline,
      store,
      skusUpdatePriceSelected,
      packagingSize,
      price_kg,
      setTwoDecimal,
      isBlockQuotePrice,
      isDisableBtn,
      isNewBiggerPrice,
      quotedTypeKey,
      selectQuoteType,
      uom,
      chooseUom,
      isOpenUomRef,
      openSelectUom,
      openToast,
      isOpenLoadingRef,
      setOpenLoading,
      IMAGE_PATH,
      DEFAULT_PRODUCT_IMAGE,
      QUOTED_PRICED_BY,
      QUOTE_RECEIVED,
      priceUpdateStr,
      unitPriceUpdateStr,
      priceUpdate,
      isCalculateByTotalPrice,
      isWeightCalculation,
      status,
      error,
      listCache,
      needRequestApproval,
      updatePrice,
      updatePriceWithoutReload,
      closeQuotePrice,
      showAlert,
      sendUpdatePrice,
      saleUpdatePrice,
      isNotOrderCatchWeight,
      handleShowLimitPrice,
      handleShowPriceKg,
      pricedByOom
    };
  }
});
</script>
<style src="../style.scss" lang="scss" scoped></style>
