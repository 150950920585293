<template>
  <ion-page class="detail-product">
    <ion-skeleton-text animated style="widh: 100vw; height: 270px"></ion-skeleton-text>
    <ion-content>
      <ion-list class="ion-padding">
        <ion-label>
          <h3>
            <ion-skeleton-text animated style="width: 50%; height: 22px"></ion-skeleton-text>
          </h3>
          <br />
          <p>
            <ion-skeleton-text animated style="width: 30%; height: 16px"></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text animated style="width: 20%"></ion-skeleton-text>
          </p>
        </ion-label>
        <br />
        <ion-label>
          <h3>
            <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
          </h3>
          <p>
            <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
          </p>
          <br />
          <h3>
            <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
          </h3>
          <p>
            <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
          </p>
        </ion-label>
      </ion-list>
    </ion-content>
    <ion-footer class="ion-padding">
      <ion-label>
        <h3>
          <ion-skeleton-text animated style="width: 25%"></ion-skeleton-text>
        </h3>
        <p>
          <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
        </p>
        <p>
          <ion-skeleton-text animated style="width: 15%"></ion-skeleton-text>
        </p>
      </ion-label>
      <ion-row>
        <ion-label>
          <p>
            <ion-skeleton-text
              animated
              style="width: 91vw; height: 48px; border-radius: 10px"
            ></ion-skeleton-text>
          </p>
        </ion-label>
      </ion-row>
    </ion-footer>
  </ion-page>
</template>
<script>
export default {
  name: 'ProductDetailSkeleton'
};
</script>
<style src="./style.scss" lang="scss" scoped></style>
