<template>
  <ion-button
    class="favorite-btn ion-no-padding mx-2"
    :disabled="disableFavorite"
    @click="setFavorite"
    fill="clear"
    slot="icon-only"
    :color="isFavorite ? 'red' : 'grey4'"
  >
    <ion-img class="favorite" :src="isFavorite ? favorite : unfavorite"></ion-img>
  </ion-button>
</template>
<script>
import favorite from '@/assets/icons/shared/favorite.png';
import unfavorite from '@/assets/icons/shared/unfavorite.png';
import { ACTIONS as ACTIONS_PRODUCT } from '@/modules/sale/store/product/actions';
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  props: ['isFavorite', 'selectedCompany', 'skusSelected', 'allowAttach', 'specsOosSelected'],
  emits: ['update-local-favorite'],

  setup(props, { emit }) {
    const store = useStore();

    // Actions
    const addFavorite = async (params) =>
      await store.dispatch('sale/product/' + ACTIONS_PRODUCT.ADD_FAVORITE, params);
    const removeFavorite = async (params) =>
      await store.dispatch('sale/product/' + ACTIONS_PRODUCT.REMOVE_FAVORITE, params);

    const setFavorite = async () => {
      if (!props.allowAttach) {
        return;
      } else {
        if (props.skusSelected.is_favorite) {
          // call remove favorite
          await removeFavorite({
            item: {
              account_number: props.selectedCompany.account_number,
              sku_id: props.skusSelected.sku_id
            }
          });
        } else {
          // call add favorite
          await addFavorite({
            item: {
              account_number: props.selectedCompany.account_number,
              user_id: props.selectedCompany.id,
              list_sku_id: [props.skusSelected.sku_id]
            }
          });
        }

        emit('update-local-favorite');
      }
    };

    const disableFavorite = computed(() => {
      return !props.allowAttach || props.specsOosSelected < 1;
    });

    return {
      unfavorite,
      favorite,
      disableFavorite,
      addFavorite,
      removeFavorite,
      setFavorite
    };
  }
};
</script>
<style scoped>
.favorite {
  width: 32px;
  height: 32px;
}
</style>
